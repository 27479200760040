import { createTheme } from "@mui/material/styles";
import components from "./components";
import typography from "./typography";
import palette from "./palette";

const appCreateTheme = () => {
  return createTheme({
    components,
    typography,
    palette,
  });
};

export { appCreateTheme };
