import Dropdown from "components/mui/Dropdown";
import DropdownItem from "components/mui/DropdownItem";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";

const Filter = ({ onSelect }) => {
  const dropdownRef = useRef(null);

  const [selected, setSelected] = useState({
    type: "all",
    label: "Alle ejendomme",
  });

  const options = [
    { type: "all", label: "Alle ejendomme" },
    { type: "Postillionen", label: "Postillionen" },
    { type: "Dannebroge", label: "Dannebroge" },
    { type: "Neptunus", label: "Neptunus" },
    { type: "Hvide Falk", label: "Hvide Falk" },
  ];

  const handleSelect = (option) => {
    setSelected(option);
    onSelect(option.type);
    dropdownRef.current.closeMenu();
  };

  return (
    <>
      <Dropdown ref={dropdownRef} buttonText={selected.label}>
        {options.map((option, i) => (
          <DropdownItem
            key={i}
            selected={selected.type === option.type}
            onClick={() => handleSelect(option)}
          >
            {option.label}
          </DropdownItem>
        ))}
      </Dropdown>
    </>
  );
};

Filter.propTypes = {
  onSelect: PropTypes.func,
};

Filter.defaultProps = {
  onSelect: () => {},
};

export default Filter;
