import { useAtom } from "jotai";
import { useEffect, useCallback } from "react";
import pLimit from 'p-limit';
import { cacheImg, priorityQueueAtom, fetchPromisesAtom } from "state/store.global";

// Create a p-limit instance with a concurrency limit
const limit = pLimit(30); // Adjust the limit as needed

export const useCachedImages = (shouldFetch, imageUrl, priority = 1) => {
  const [cacheImgState, setCacheImg] = useAtom(cacheImg);
  const [priorityQueue, setPriorityQueue] = useAtom(priorityQueueAtom);
  const [fetchPromises, setFetchPromises] = useAtom(fetchPromisesAtom);

  const loadImage = useCallback(async () => {
    if (!imageUrl) return null;

    // Check if the image is already cached
    if (cacheImgState[imageUrl]) {
      return cacheImgState[imageUrl];
    }

    // Check if there's an error for this URL to avoid re-fetching immediately
    if (cacheImgState[imageUrl] === 'error') {
      return null; // Skip re-fetching if marked as an error
    }

    // Check if there's already a fetch in progress for this URL
    if (fetchPromises[imageUrl]) {
      return fetchPromises[imageUrl];
    }

    // Local queue copy to avoid frequent state updates
    let queueCopy = [...priorityQueue];

    // Check if the image is already in the priority queue
    const isAlreadyInQueue = queueCopy.some(item => item.imageUrl === imageUrl);
    if (!isAlreadyInQueue) {
      // Add the fetch request to the priority queue
      queueCopy.push({ imageUrl, priority });
      queueCopy.sort((a, b) => a.priority - b.priority); // Sort the queue here
      setPriorityQueue(queueCopy); // Update state after sorting
    }

    // Create a fetch promise and store it in the atom
    const fetchPromise = limit(async () => {
      try {
        const response = await fetch(imageUrl);
        if (!response.ok) throw new Error('Network response was not ok');
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);

        // Update cache
        setCacheImg(prevCache => ({
          ...prevCache,
          [imageUrl]: blobUrl,
        }));

        // Remove the processed URL from the queue and fetch promises
        setFetchPromises(prevPromises => {
          const { [imageUrl]: _, ...rest } = prevPromises;
          return rest;
        });
        queueCopy = queueCopy.filter(item => item.imageUrl !== imageUrl);
        setPriorityQueue(queueCopy); // Update state with filtered queue

        return blobUrl;
      } catch (error) {
        console.error(`Failed to fetch image: ${imageUrl}`, error);
        
        // Mark image as failed in cache to avoid retries
        setCacheImg(prevCache => ({
          ...prevCache,
          [imageUrl]: 'error', // Mark the error in cache
        }));

        // Clean up fetch promises to prevent loops
        setFetchPromises(prevPromises => {
          const { [imageUrl]: _, ...rest } = prevPromises;
          return rest;
        });

        throw error; // Re-throw the error if needed
      }
    });

    // Store the fetch promise to avoid duplicate fetches
    setFetchPromises(prevPromises => ({
      ...prevPromises,
      [imageUrl]: fetchPromise,
    }));

    return fetchPromise;
  }, [imageUrl, cacheImgState, fetchPromises, priorityQueue]);

  useEffect(() => {
    if (shouldFetch) {
      loadImage().catch(() => {
        // Handle any errors caught during the fetch process
      });
    }

    return () => {
      // Optionally clear promises on cleanup
    };
  }, [loadImage, shouldFetch]);

  return cacheImgState[imageUrl] !== 'error' ? cacheImgState[imageUrl] || null : null;
};
