import { ImgWrapper } from 'components/ImgContainer/ImgWrapper';
import { getImages, getThumb } from 'components/List/List.Utilities';
import { useActiveUnit } from 'components/Unit';
import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { get, useGet } from 'state/jotai';
import { getIsoData } from 'state/jotai.isometryDataHooks';
import { active_project } from 'state/store.global';
import { _project } from 'state/store.projects';


export const BackgroundUnitLoading = () => {
  const here = useLocation().pathname;
  const atList = here.includes('list');
  const activeUnit = useActiveUnit();

  const [startLoading, setStartLoading] = useState(false);
  const [range, setRange] = useState([0, 5]);
  const [firstBatchDone, setFirstBatchDone] = useState(false);

  const loadBatchSize = 10;
  const loadInterval = 10000;

  const { pin, pid } = useGet(active_project);
  const status = getIsoData('status');
  const units = get(_project, 'units', 'Units', false) || [];

  // Keep track of progress for each pin/pid combination
  const loadingProgressRef = useRef({});

  // Initialize or restore progress when pin or pid changes
  useEffect(() => {
    const key = `${pin}_${pid}`;

    if (loadingProgressRef.current[key] && (atList || status === 'DONE')) {
      // Restore previous progress for this pin/pid
      const { range: savedRange, firstBatchDone: savedFirstBatchDone } = loadingProgressRef.current[key];
      setRange(savedRange);
      setFirstBatchDone(savedFirstBatchDone);
    } else {
      // Start fresh for a new pin/pid
      setRange([0, 5]);
      setFirstBatchDone(false);
    }
    setStartLoading(false); // Initially don't start loading until status is 'DONE'
  }, [pin, pid, status]);

  // Save progress when loading completes
  useEffect(() => {
    if(pin >=0 && pid) {
      const key = `${pin}_${pid}`;
      loadingProgressRef.current[key] = { range, firstBatchDone };
    }
  }, [range, firstBatchDone, pin, pid]);

  // Start loading only when status is 'DONE'
  useEffect(() => {
    if (units && units.length && (atList || status === 'DONE')) {
      setStartLoading(true);
    }
  }, [status, atList, units.length]);

  // Manage the interval for fetching images
  useEffect(() => {
    let interval;
    if (startLoading && (atList || status === 'DONE')) {
      if (!firstBatchDone && range[1] < units.length) {
        interval = setInterval(() => {
          setRange((prevRange) => [prevRange[1], Math.min(prevRange[1] + loadBatchSize, units.length)]);
        }, loadInterval);
      } else if (range[1] >= units.length && !firstBatchDone) {
        setFirstBatchDone(true);
        setRange([0, 5]); // Reset range for next batch
      }
    }
    return () => clearInterval(interval);
  }, [startLoading, range, units.length, firstBatchDone, status]);

  // Load images for a unit
  const loadImagesForUnit = (unit, j, loadStage = 'initial') => {
    const sliderImages = getImages(unit, true) ?? [];
    const sliderThumbImages = getThumb(unit, true) ?? [];

    // Initial load: first image and thumbnail (A image)
    if (loadStage === 'initial') {
      return (
        <div key={j} style={{ display: 'none' }}>
          <ImgWrapper priority={1} bgLoad={true} img={sliderImages[0]?.url} />
          <ImgWrapper priority={1} bgLoad={true} img={sliderThumbImages[0]?.url} />
        </div>
      );
    }

    // Secondary load: 2-3 next images after the first one
    if (loadStage === 'secondary') {
      const nextImages = sliderImages.slice(1, 4); // Get the next 2-3 images (skip first)
      const nextThumbs = sliderThumbImages.slice(1, 4); // Get the next thumbnails (skip first)
      return (
        <div key={j} style={{ display: 'none' }}>
          {nextImages.map((img, i) => (
            <div key={i + j + 'sliderImage'} style={{ display: 'none' }}>
              <ImgWrapper priority={2} bgLoad={true} img={img?.url} />
            </div>
          ))}
          {nextThumbs.map((img, i) => (
            <div key={i + j + 'sliderThumbImage'} style={{ display: 'none' }}>
              <ImgWrapper priority={2} bgLoad={true} img={img?.url} />
            </div>
          ))}
        </div>
      );
    }

    if (loadStage === 'primary') {
      return (
        <div key={j} style={{ display: 'none' }}>
            {sliderThumbImages.map((img, i) => (
            <div key={i + j} style={{ display: 'none' }}>
              <ImgWrapper priority={1} bgLoad={true} img={img?.url} />
            </div>
          ))}
          {sliderImages.map((img, i) => (
            <div key={i + j} style={{ display: 'none' }}>
              <ImgWrapper priority={1} bgLoad={true} img={img?.url} />
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <>
      {/* Initial load: first image and thumbnails */}
      {startLoading && !firstBatchDone &&
        units.slice(0, range[1]).map((unit, j) => loadImagesForUnit(unit, j, 'initial'))
      }

      {/* Secondary load: 2-3 next images */}
      {firstBatchDone &&
        units.slice(0, range[1]).map((unit, j) => loadImagesForUnit(unit, j, 'secondary'))
      }

      {/* Full unit image loading */}
      {loadImagesForUnit(activeUnit.data, 'allUnitImageLoading...', 'primary')}
    </>
  );
};
