import styled from 'styled-components/macro'
import { useFetch_Colors } from 'hooks/fetch/useFetch_Colors'
  

export const Nav = ({setActive,max,active}) => {

  const clr     = useFetch_Colors()
  const left    = () => setActive(a => (a>0) ? a - 1 : a)
  const right   = () => setActive(a => (a<max) ? a + 1 : a)

  return (
    <>
      <Box {...clr}>
        <Arrow 
          {...clr}
          dis={active===0?true:false} 
          onClick={left}>{'<'}
        </Arrow>
        <Vert {...clr}/>
        <Arrow 
          {...clr}
          dis={active===max?true:false} 
          onClick={right}>{'>'}
        </Arrow>
      </Box>
    </>
  )
}

const Vert = styled.div`
  height: 20px;
  width: 2.5px;
  background: ${({clr}) => clr.primary_text };
`
const Arrow = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border:0;
  color:${({dis,clr}) => dis?'#9e9e9e':clr.primary_text};
  font-size: 3em;
  cursor: pointer;
`
const Box = styled.div`
  position: absolute;
  height:50px;
  /* border:1px solid #fff; */
  bottom: 0;
  left: 50%;
  transform: translatex(-50%);
  border-radius: 12px 12px 0 0;
  padding: 5px 15px;
  display: flex;
  background-color: ${ ({clr}) => clr.primary };
  z-index: 1;
  align-items: center;
  justify-content: center;
  gap: 20px;
  opacity: 0.7;
  color: ${ ({clr}) => clr.primary_text };
  padding-bottom:15px;
`