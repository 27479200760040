import { formatters }     from 'components/List/List.Utilities'
import { Cell, circ, td } from 'components/List/Table/Table.style'
import { Icon }           from 'assets/Icons_'
import { useAtom }        from 'state/jotai'
import { favorites }      from 'state/store.global'
import { compares }       from 'state/store.global'

export const Cells = ({unit,col,i,j,isRent}) => {
  
  const pris = isRent ? 'Husleje' : 'Kontantpris'
  const likes    = useAtom(favorites)[0]
  const setLikes = useAtom(favorites)[1]
  const comps    = useAtom(compares)[0]
  const setComps = useAtom(compares)[1]
  const sID      = unit.Data.UnitStatusID.Value
  
  const compare    = { icon:'compare2'   , color:clr?.icons, size:18 }
  const compare_   = { icon:'compare2'   , color:'gray'    , size:18 }
  const heartempty = { icon:'heart_empty', color:clr?.icons, size:18 }
  const heartfull  = { icon:'heart_full' , color:'#863E30' , size:18 }
  
  const isLiked = likes.includes(unit.UnitID)
  const isComps = comps.includes(unit.UnitID)

  const toggleLike = (e) => {
    if ( sID == 40 ) { return }
    e.stopPropagation()
    const add = [...likes,unit.UnitID]
    const rem = [...likes].filter( i => i !== unit.UnitID )
    setLikes( isLiked ? rem : add )
  }

  const toggleComp = (e) => {
    if ( sID == 40 ) { return }
    e.stopPropagation()
    const add = [...comps,unit.UnitID]
    const rem = [...comps].filter( i => i !== unit.UnitID )
    const max = comps.length == 3
    setComps( isComps ? rem : ( max ? comps : add ) )
  }

  return (
    <>
      <div style={{width:10}}/>
      <Icon {... isComps ? compare_ : compare } click={toggleComp} count={ isComps ? comps.indexOf(unit.UnitID)+1 : 0}/>
      <div style={{width:10}}/>
      <Icon {...isLiked ? heartfull : heartempty} click={toggleLike}/>
      <div style={{width:10}}/>
      {
        col.map(({ data, header, w, key, align }) => {

          const a = align == 0 ? 'left' : align == 1 ? 'center' : 'right'
          const d = (() => {
            
            if ( header == 'Index'    ) return formatters.Index (j,data)
            if ( header == 'Pris'     ) return formatters.Pris  (i,pris)
            if ( header == 'Status'   ) return formatters.Status(i,data)
            if ( header == 'Etage'    ) return formatters.Etage (i,data)
            if ( header == 'Areal'    ) return formatters.Areal (i,data)
            if ( header == 'Værelser' ) return formatters.Rooms (i,data)
            if ( header == ''         ) return ''
            
            return formatters.Else(i, data)

          })()

          const style = ( header == 'Status' ) ? circ(d) : td
          const c = ( header == 'Status' ) ? '' : d
          
          return (
            <Cell key={key} w={w}>
              <div style={{...style,textAlign:a}}>{ c }</div>
            </Cell>
          )

        })
      }
    </>
  )
}
