import styled from 'styled-components/macro'
import { BREAK_ } from 'constants'
import { motion } from 'framer-motion'

export const Main = styled(motion.div)`
  max-width: 100vw;
  width: 424px;
  background-color: #ffffff;
  padding: 20px 40px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  top:59px;
  left:50%;
  position: fixed;
  border: 1px solid rgba(0, 0, 0, 0.1);
  ${ BREAK_.md_up } {
    top:0;
    left:calc(50% + 50px);
  }
`
export const Head = styled.div`

`

export const Options = styled.div`

`