import { Button, Menu } from "@mui/material";
import { Icon } from "components/Icons";
import { useBreak } from "hooks/useBreak";
import PropTypes from "prop-types";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useAtom } from "state/jotai";
import { iso_free } from "state/store.global";

const Dropdown = forwardRef(({ children, buttonText, selectedLabel, width,face,
  color,
  fontWeight,
  fontSize,
  lineHeight,
  xs,
  marginLeft, }, ref) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [, setRmode] = useAtom(iso_free)
  const isDesktop = useBreak('md_up')

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setRmode(false)
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  // Expose child method through the ref
  useImperativeHandle(ref, () => ({
    closeMenu() {
      setAnchorEl(null);
    },
  }));

  return (
    <>
      <Button
        onClick={handleOpenMenu}
        disableRipple
        disableFocusRipple
        sx={{
          color: color ? color : "#000000",
          background: "none",  // No background
          boxShadow: "none",  // No shadow
          fontWeight: fontWeight ? fontWeight : 400,
          fontSize: fontSize ? fontSize : 16,
          lineHeight: lineHeight,
          px: 0,
          gap: "8px",
          width: "max-content",
          transform: "scale(1)",
          transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out;",
          "&:hover": {
            background: "none",
            transform: "scale(1.1)",
            opacity: 1,
            boxShadow: "none",
          },
          "& span": {
            ml: 0,
            mr: 0
          }
        }}        
        endIcon={
          <Icon
            icon="caretDown"
            sx={{
              fontSize: "10px !important",
              pointerEvents: "none",
              color: "primary",
              transform: open ? "rotate(180deg)" : "rotate(0)",
              transition: "transform 0.3s",
            }}
          />
        }
      >
        <div className="paragraph1-regular">
          <span>{buttonText}</span>
          <span>{selectedLabel ? `: ${selectedLabel}` : ""}</span>
        </div>
        
          {/* <span style={{ fontWeight: 400 }}>{face}</span> */}
      </Button>
      <Menu
        anchorEl={anchorEl}
        disablePortal={true}
        open={open}
        onClose={closeMenu}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          marginLeft: marginLeft,
          width: width,
          "& .MuiList-root": {
            paddingBottom: "0px",
            paddingTop:"0px"
          },
          "& ul": {
            height: '100%',
          },
          "& .MuiMenuItem-root": {
            fontSize: 16,
            py: "16px",
            px: "20px",
            padding:"16px 20px 16px 0",
            "&:hover": {
              color: clr.buttons,
            },
          },
        }}
        PaperProps={{
          elevation: 3,
          sx: {
            ml: { xs: xs ? xs : "0px", md: "0" },
            color:"#fff",
            backgroundColor: `${clr.primary}e6`,
            // width: 400,
            width:!isDesktop ? "calc(100% - 32px)" : 400 ,
            maxWidth: "100%",
            maxHeight: isDesktop ? "435px" : "419px",
            "&::-webkit-scrollbar": { display: "none" },
            scrollbarWidth: "none",
            overflow: "hidden",
            left:!isDesktop ? "16px!important" : "",
            borderRadius: "6px",
            // left: isDesktop ? "880px !important" : ""
          },
        }}
      >
        {children}
      </Menu>
    </>
  );
});

Dropdown.propTypes = {
  children: PropTypes.node,
  buttonText: PropTypes.string,
};

Dropdown.defaultProps = {
  buttonText: "",
};

Dropdown.displayName = "Dropdown";

export default Dropdown;
