import { css } from 'styled-components'

export const all = css`
  
  
  *,
  
  ::before,
  
  ::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  :focus {
    outline: none;
  }

`