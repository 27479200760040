import styled from 'styled-components/macro'

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  /* background-color: #000000ec; */
  background-color: #000000;
  z-index: 1002;
`
export const Img = styled.img`
  display: block;
  text-align: center;
  width: auto;
  max-height: 100%;
  margin:0 auto;
`