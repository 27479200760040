import CodeIcon from "@mui/icons-material/Code";
import {
  InputBase,
  InputLabel,
  Select,
  Slider,
  SliderThumb,
  styled,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const CustomLabel = styled(InputLabel)(() => ({
  display: "block",
  position: "unset",
  color: "inherit",
  fontSize: 16,
  transform: "none",
  fontWeight: 400,

  "& > span": {
    color: "#7B2C1C",
  },
}));

const CustomInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(1),
  },
  color: "#000",
  "& .MuiInputBase-input": {
    borderRadius: 6,
    position: "relative",
    backgroundColor: "#ffffff",
    border: `1px solid ${clr.primary_text}`,
    // borderColor: `${theme.palette.primary.main}55`,
    fontSize: 16,
    width: "100%",
    padding: `${theme.spacing(1.25)} ${theme.spacing(1.5)}`,
    transition: "border-color 0.3s",
    height: "44px",
    "&:focus": {
      borderColor: `${theme.palette.primary.main}`,
    },
  },
  "&.Mui-error .MuiInputBase-input": {
    borderColor: theme.palette.error.main,
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  color: "#000",
  "label + &": {
    marginTop: theme.spacing(1),
  },
  boxShadow: 'none', 
  '.MuiOutlinedInput-notchedOutline': { border: '1.3px solid #fff' },
  
  // "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
  //   {
  //     border: '0.5px solid yellow',
  //       borderRadius: 6,
  //   },
  // "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
  //   {
  //     border: '0.5px solid red',
  //       borderRadius: 6,
  //   },
  "& .MuiInputBase-input": {
    borderRadius: 6,
    position: "relative",
    backgroundColor: "#ffffff",
    fontSize: 16,
    width: "100%",
    padding: `${theme.spacing(1.25)} ${theme.spacing(1.5)}`,
    transition: "border-color 0.3s",
  },

  ".MuiSelect-select:focus": {
    backgroundColor: 'white',
    borderRadius: 6,
  },
  "input:not(:placeholder-shown) + .css-1e88mfs-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1e88mfs-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1e88mfs-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
    backgroundColor: 'green'
  },
//   ".css-1e88mfs-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1e88mfs-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1e88mfs-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
//     backgroundColor: 'red'
// },
  "&.Mui-error .MuiInputBase-input": {
    borderColor: theme.palette.error.main,
  },
}));

const CustomSlider = styled(Slider)(() => ({
  color: "#ffffff",
  height: 5,
  marginTop: "36px",
  padding: "15px 0",
  borderRadius: 6,
  "& .MuiSlider-thumb": {
    height: 40,
    width: 40,
    border: "3px solid #F3F3F3",
    "&:focus, &:hover, &.Mui-active": {},
    "& .MuiSvgIcon-root": {
      fill: "#000000",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: "1",
  },
  "& .MuiSlider-mark": {
    display: "none",
  },
  "& .MuiSlider-markLabel": {
    color: "currentColor",
    marginTop: 5,
  },
  "& .MuiSlider-valueLabel": {
    backgroundColor: "currentColor",
  },
  "& .MuiSlider-valueLabel span": {
    color: "#000000",
  },
}));

const CustomSliderThumb = (props) => {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <CodeIcon sx={{ width: 24 }} />
    </SliderThumb>
  );
};

CustomSliderThumb.propTypes = {
  children: PropTypes.node,
};

export { CustomLabel, CustomInput, CustomSlider, CustomSelect };
