import styled from 'styled-components/macro'
import { BREAK_ } from 'constants'
import { motion } from 'framer-motion'

export const Main = styled(motion.div)`
  background-color: ${({ clr }) => `${clr.primary}e6`};
  border-radius: 8px;
  position: fixed;
  bottom: 55px;
  left: ${({ isDesktop }) => (isDesktop ? '48px' : '15px')};
  width: ${({ isDesktop }) => (isDesktop ? '520px' : '345px')};
  z-index: 1;
`;

export const Option = styled.div`
  display: flex;
  cursor: pointer;
  font-size: 1.0625rem;
  border-bottom: 1px solid #fff;
  justify-content: flex-start; /* Align options to flex-start */
  padding: 10px 0; /* Add padding for better spacing */
`;

export const Cell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align content to the start */
  padding: 20px;
  flex: 1;
  width: 100%; /* Ensure Cell takes up full width */
`;

export const Text = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  flex-direction: row; /* Keep content in a row */
  font-weight: 600;
  width: 100%;
  color: ${({ clr }) => clr.primary_text};
  justify-content: flex-start; /* Ensure content aligns to start */
  padding-bottom: 10px;
`;

export const SubText = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 400;
  width: 100%; /* Make sure it takes full width */
  color: #fff;
  justify-content: flex-start; /* Ensure alignment */
  line-height: 1.2;
`;

export const Values = styled.div`
  padding-bottom: 5px;
  width: 100%; /* Ensure full width */
  text-align: left; /* Make sure text is aligned left */
  white-space: pre-line;
`;
