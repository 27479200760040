const typography = {
  fontFamily: "Cerebri sans",

  body1: {
    lineHeight: 1.15,
    color: "#000000",
  },
  fontWeightBold: 700,
};

export default typography;
