import { Box, Typography, } from "@mui/material";
import ContactForm from "components/Contact/ContactForm";
import PropTypes from "prop-types";
import React from "react";
import Unit from "./Unit.model";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";

function SignUpForm({ unit }) {
  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  return (
    <Box id="unit_page_contact_form" sx={{ pt: 7, pb: { md: 5, xs: 0 }, px: { md: 4, xs: 2.5 }, }} >
      <Box sx={{ maxWidth: 525, mx: "auto", }} >
        <ContactForm grid unit={unit}>
          <Box sx={{ maxWidth: 320, mb: 4, }} >
            <Box sx={{ fontWeight: 700, lineHeight: 1.4, }} >
              { buttonsLabel?.filter( btn => btn.UILabelProperty === 'SidefodformularOverskrift')[0]?.Label }
            </Box>
            <br />
            <Box sx={{ fontWeight: 400, lineHeight: 1.4, fontSize: 15}} >
            { buttonsLabel?.filter( btn => btn.UILabelProperty === 'SidefodformularUnderoverskrift')[0]?.Label }
            </Box>
          </Box>
        </ContactForm>
      </Box>
    </Box>
  );
}

SignUpForm.propTypes = {
  unit: PropTypes.instanceOf(Unit).isRequired,
};

export default SignUpForm;
