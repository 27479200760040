import styled from 'styled-components/macro'

export const Main = styled.div`
  /* border: 10px solid black; */
  width: 100%;
  height: 100%;
  padding: 63px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

export const Box = styled.div`
  width: 200px;
  height: 200px;
  border: 10px solid pink;
  color:black;
  font-size:4rem;
  display:flex;
  justify-content:center;
  align-items:center;
  user-select:none;
`