import styled from 'styled-components/macro'
import { sb } from 'styles/styles.scrollbar'

export const Main = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  ${sb.show};
  gap: 38px;
  /* border:2px solid red; */
`

export const Box = styled.div`
  flex: 0 0 313px;
  height: 286px;
  background: ${({ clr }) => clr?.primary};
  justify-content: ${({type}) => type == 'top' ? 'flex-start' : 'center' };
  align-items: ${({type}) => type == 'top' ? 'flex-start' : 'center' };
  display: flex;
  position: relative;
  font-size: 1.25rem;
  /* font-weight: 600; */
  padding: 30px;
  p {
    text-align: left;
    line-height: 1.4;
  }
  a {
    color: ${({ clr }) => clr?.primary_text};
    font-weight: 600;
  }
  background-image: ${({ img }) => `url(${img})`};
  background-size: cover;
  /* filter: drop-shadow(0px 3px 2px #00000010) */
`

export const Double = styled.div`
  flex: 0 0 664px;
  height: 370px;
  max-width: 100%;
  /* border:10px solid orange; */
  overflow: hidden;
  object-fit: cover;
  background-image: ${({ img }) => `url(${img})`};
  background-size: cover;
  background-color: #F3F3F3;
  padding: 30px;
  font-size: 1.5625rem;
  font-weight: 600;
`

export const Wrap = styled.div`
  width:100%;
  height:fit-content;
  display:flex;
  /* border:10px solid red; */
  /* padding:15px; */
  gap:38px 38px;
  justify-content:center;
  align-items:flex-start;
  align-content:flex-start;
  flex-wrap:wrap;
`