import styled, {css} from 'styled-components/macro'
import { motion } from 'framer-motion'

const blurstyles = css`
  backdrop-filter: blur(9px);
  top: 0;
  position: absolute;
  color: ${clr.primary_text};
  padding:5px 0;
  height: 40px;
`
const stdstyles = css`
  position: relative;
  height: 70px;
`
export const Row = styled.nav(({ blur }) => css`
  ${blur ? blurstyles : stdstyles }
  z-index: 3;
  background: transparent;
  width: 100%;
  
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${blur ? '#fff' : '#000'};
  font-size:0.875rem;
  ${'' /* border: 5px solid red; */}
`)

export const Box = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  height: 100%;
`
export const Outline = styled(motion.div)`
  position: relative;
  display: flex;
  border-radius: 19px;
  padding: 0 20px;
`
export const Face = styled.div`
  padding-right: 20px;
`
export const Name = styled.div`
  white-space: nowrap;
  font-weight: 600;
  padding-right: 20px;
`
export const Front = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
`