import { useState } from 'react'
import styled from 'styled-components/macro'

export const Cursor = ({ children }) => {

  const [cursor, setCursor] = useState('grab')

  return (
    <CursorWrap
      onMouseDown={() => setCursor('grabbing')}
      onMouseUp={() => setCursor('grab')}
      cursor={cursor}
    >
      {children}
    </CursorWrap>
  )
}

const CursorWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  cursor:${({cursor})=>cursor};
  width: 100%;
  height: 100%;
`
