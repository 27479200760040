import { css } from 'styled-components'
import { sb } from './styles.scrollbar'

export const body = css`
  body {
    -webkit-text-size-adjust: 100%;
    -webkit-overflow-scrolling: touch; /* enables “momentum” (smooth) scrolling */
    // overscroll-behavior: none;
    ${'' /* overflow-y: scroll; */}
    // overflow:hidden;
    height: 100%;
    ${ sb.hide };
    line-height: 1.15;

  }
`