export const useClick = () => {

  const click = (e) => {

    const val = e.target.id

    console.log( 'click on project, with id: ', val )

  }

  return click

}