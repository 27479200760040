import { useState, useEffect } from "react";
import { Backdrop } from "styles/styles.backdrop";
import { useAtom, useGet } from "state/jotai";
import { Box, Stack } from "@mui/material";
import { PREPAIDCALCULATIONS } from "api/api";
import { Icon } from "assets/Icons_";
import styled from "styled-components";
import { active_project, vis_prepaidrent } from "state/store.global";
import { useActiveUnit } from "components/Unit";
import { Container, Inner } from "./HousingBenefit.style";
import { _bootstrap } from "state/store.bootstrap";
import { LottiPlay } from "components/LottieAnimation/LottiePlay";

const StyledImage = styled.img`
  width: 637px;
  height: 200px;
  object-fit: cover;
`;

const Row = styled.div`
  padding: 0.4rem 0;
  font-size: 16px;
  font-weight: 400;
  font-family: Arial;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Line = styled.div`
  min-height: 1px;
  max-height: 1px;
  width: 100%;
  background: lightgray;
`;

export const PrePaidRentPricePortal = ({PPRC, close}) => {
  const [, setPrePaidRent] = useAtom(vis_prepaidrent);

  const unit = useActiveUnit();
  const { pid } = useGet(active_project);
  const type = useGet(_bootstrap).type;
  const mono = type === "mono";

  const [data, setData] = useState({ rent: null, prepaid_rent: null, prepaid_rent_number_of_month: null });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

 

  const isInvalidValue = (value) =>
    value === null || value === "0" || value === "0,0" || value === "999999" || value === "999.999";

  
  const imageUrl = unit.getImages()[5]?.url;

  const icon = {
    icon: "close6",
    color: "#fff",
    wrap: { position: "absolute", top: 20, right: 20 },
    size: "20",
    click: close,
  };

  return (
    <>
      <Backdrop  onClick={close} />
      <Container>
       
            <StyledImage src={imageUrl} alt="Unit Image" />
            <Icon {...icon} />
            <Inner>
              <h2>Hvad er forudbetalt husleje?</h2>
              <div style={{ display: "flex", paddingBottom: "24px", fontSize: "17px" }}>
                <p>Forudbetalt huseleje er udregnet ved husleje x antal måneder i forudbetalt husleje</p>
              </div>
              <Box>
                <div>
                  {!isInvalidValue(PPRC?.rent) && (
                    <>
                      <Row>
                        <div>Husleje</div>
                        <div>{PPRC?.rent} kr.</div>
                      </Row>
                      <Box sx={{ height: 5 }} />
                    </>
                  )}
                  {!isInvalidValue(PPRC?.prepaid_rent_number_of_month) && (
                    <>
                      <Line />
                      <Box sx={{ height: 5 }} />
                      <Row>
                        <div style={{ display: "flex", gap: "4px" }}>
                          Antal måneder forudbetalt husleje
                        </div>
                        <div>{PPRC?.prepaid_rent_number_of_month} mdr.</div>
                      </Row>
                    </>
                  )}
                </div>
                <Line />
                <div style={{ height: 10 }} />
                {!isInvalidValue(PPRC?.prepaid_rent) && (
                  <Row>
                    <div style={{ fontWeight: "600" }}>Forudbetalt husleje</div>
                    <div>
                      <div style={{ fontWeight: "600" }}>{`${PPRC?.prepaid_rent} kr.`}</div>
                    </div>
                  </Row>
                )}
                <Box sx={{ height: 5 }} />
                <Line />
              </Box>
              <Stack direction="row" spacing="1px" justifyContent="center"></Stack>
            </Inner>
          
      </Container>
    </>
  );
};
