import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .on('languageChanged', (lng) => {
    
    // console.log( 'on.languagechanged', lng) 
    // console.log( i18n.options.fallbackLng [0] )
    // console.log( window.location.pathname )
     // if the language we switched to is the default language we need to remove the /en from URL
    // if (lng === i18n.options.fallbackLng[0]) {
      // if (window.location.pathname.includes('/' + i18n.options.fallbackLng[0])) {
        // const newUrl = window.location.pathname.replace('/' + i18n.options.fallbackLng[0], '')
        // window.location.replace(newUrl)
      // }
    // }


  })
  .init({
    debug: false,
    fallbackLng: 'da',
    // lng: 'sv',
    interpolation: { escapeValue: false },
    react: { useSuspense: false },
    backend: { loadPath: '/locales/{{lng}}.json' },
    // backend: { loadPath: './translations/translations.json' },
    detection:{
      order: [ 'path' ],
      lookupFromPathIndex: 0, // keys or params to lookup language from
    }
  })

export default i18n


// bindI18n: 'loaded languageChanged',
// bindI18nStore: 'added',