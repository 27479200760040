import { atom } from 'jotai'

export const _lowerQualityIsometry = atom({
  frames          : {},
  percent         : {},
  status          : {},
  activeframe     : {},
  svgdimensions   : {},
  activescale     : {},
  config          : {},
  snapindex       : {},
  imageindex      : {},
  showoverlay     : {},
  mode            : {},
  tiny            : [],
  overlays        : []
})