import styled          from 'styled-components/macro'
import { Icon }        from 'assets/Icons_'
import { useAtom }     from 'state/jotai'
import { favorites }   from 'state/store.global'
import { compares }    from 'state/store.global'
import { useBreak }    from 'hooks/useBreak'
import { BREAK_ }      from 'constants'
import { vis_compare } from 'state/store.global'


export const LikeShare = ({data}) => {
  
  const setComp   = useAtom(vis_compare)[1]
  const isDesktop = useBreak('md_up')
  const likes     = useAtom(favorites)[0]
  const setLikes  = useAtom(favorites)[1]
  const comps     = useAtom(compares)[0]
  const setComps  = useAtom(compares)[1]
  const UnitID    = data.UnitID.Value

  const share      = { icon: 'share'      , color: clr?.icons, size: 25 }
  const heartempty = { icon: 'heart_empty', color: clr?.icons, size: 25 }
  const heartfull  = { icon: 'heart_full' , color: '#863E30' , size: 25 }
  const compare    = { icon:'compare2'    , color: clr?.icons, size: 25, count:'x' }
  
  const isLiked = likes.includes(UnitID)

  const removeComp = (e) => {
    e.stopPropagation()
    if ( comps.length <= 1 ) {
      setComp(false)
    }
    setComps(a => [...a].filter(i => i !== UnitID))
    return
  }

  const toggleLike = (e) => {
    e.stopPropagation()
    const add = [...likes,UnitID]
    const rem = [...likes].filter( i => i !== UnitID )
    setLikes( isLiked ? rem : add )
  }

  return (
    <>
      <Container br={isDesktop}>
        <Section>
          <Icon {...share} />
          { isDesktop && 'Del bolig' }
        </Section>
        <Section onClick={removeComp}>
          <Icon {...compare}/>
          { isDesktop && 'Fjern' }
        </Section>
        <Section onClick={toggleLike}>
          <Icon {...(isLiked ? heartfull : heartempty)} />
          { isDesktop && ( isLiked ? 'Din favorit' : 'Gem bolig' ) }
        </Section>
      </Container>
    </>
  )
}

const Container = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: ${({ br }) => (br ? 'space-between' : 'center')};
  gap: ${({ br }) => (br ? 'unset' : '20px')};
  padding: 40px 10%;
  ${BREAK_.md_dn} {
    padding:0 10% 10px 10%;
  }
  font-weight: 600;
  background: #f7f7f7;
`
const Section = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 0.5;
  font-size: 1.0625rem;
`