import styled from 'styled-components/macro'
import { sb } from 'styles/styles.scrollbar'

export const Container = styled.div`
  max-width: ${ ({count}) => count * (1850/3) }px;
  width:100%;
  height:${ ({br}) => br ? 'calc(100% - 30px)' : '100%' };
  position:absolute;
  bottom:0;
  box-shadow: 0px 1px 8px #000000;
  white-space:pre;
  display:flex;
  flex-direction:column;
`
export const Main = styled.div`
  background:#EDEDED;
  height:100%;
  overflow-y:scroll;
  ${ sb.hide };
`
export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: #00000095;
  z-index: 1;
`
export const Top = styled.div`
  width: 100%;
  flex: 0 0 60px;
  display: flex;
  position: relative;
  gap: 5px;
  padding: 10px 30px 5px 30px;
  font-weight: 600;
  font-size: 0.9375rem;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 2;
  flex-direction: column;
  justify-content: center;
`
export const Col = styled.span`
  color:${({clr})=>clr?.heart };
`