import { useEffect }            from 'react'
import { useProjectSetup }      from 'hooks/useProjectSetup'
import { useFetch_Bootstrap }   from 'hooks/fetch/useFetch_Bootstrap'
import { useFetch_Colors }      from 'hooks/fetch/useFetch_Colors'
import { useFetch_Navigation }  from 'hooks/fetch/useFetch_Navigation'
import { useFetch_Highlights }  from 'hooks/fetch/useFetch_Highlights'
import { usePreload_Logos }     from 'hooks/preload/usePreload_Logos'
import { useSetAtom }        from 'state/jotai'
import { renderLayout }         from 'state/store.global'
import { _bootstrap }           from 'state/store.bootstrap'

export const Boot = () => {

  const setRenderLayout = useSetAtom(renderLayout)

  const dependency = 0 
    
    + useFetch_Bootstrap  ()
    + useFetch_Colors     ().res
    + useFetch_Navigation ().res
    + useFetch_Highlights ().res

    + usePreload_Logos    ().res

    + useProjectSetup     ().res
    === 6
  
  useEffect( ()=> {
  
    if ( dependency ) setRenderLayout(true)
  
  },[dependency])

  return

}

// Boot.whyDidYouRender = true
