import { atom } from 'jotai'

export const active_subiso = atom('')
export const available_subs = atom([])

export const _subiso = atom({
  frames        : {},
  percent       : {},
  status        : {},
  activeframe   : {},
  svgdimensions : {},
  activescale   : {},
  config        : {},
  snapindex     : {},
  imageindex    : {},
  showoverlay   : {},
  mode          : {},
  tiny          : [],
  overlays      : []
})