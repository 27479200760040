import { Main, Box } from './Help_.style'
import { HozSwipe } from './Help_.Swiper'
import { SwiperSlide }  from 'swiper/react'

export const Help_ = () => {
  return (
    <Main>
      <span>MAIN</span>
      <HozSwipe>
  
        <SwiperSlide key={1}>
          <Box>1</Box>
        </SwiperSlide>
  
        <SwiperSlide key={2}>
          <Box>2</Box>
        </SwiperSlide>

        <SwiperSlide key={3}>
          <Box>3</Box>
        </SwiperSlide>

        <SwiperSlide key={4}>
          <Box>4</Box>
        </SwiperSlide>
  
      </HozSwipe>
      <span>SUB</span>
    </Main>
  )
}