import React from "react";
import Favorites from "components/Favorites/Favorites";
import { Box } from "@mui/material";
import { Scrollbar } from "react-scrollbars-custom";
import { useBreak } from "hooks/useBreak";

export const ViewFavorites = () => {
  const isDesktop    = useBreak('md_up')

  return (
    <>
    {
      isDesktop ?
      <Scrollbar trackYProps={{
        renderer: (props) => {
          const { elementRef, ...restProps } = props;
          return <span {...restProps} ref={elementRef}
          className="trackY" style={{ ...restProps.style }} />;
        },
      }}>
        <RenderComponent />
      </Scrollbar>
      :
      <RenderComponent />
    }
    </>
  );
};



const RenderComponent = () => {
  return     <>
      <Box sx={{ pb: {xs:"0px", md: "0px" }}}>
        <Favorites />
      </Box>
    </>
}