import { _project }       from 'state/store.projects'
import { array }          from 'utilities/utility.misc'
import { getProjectData } from 'state/jotai.projectDataHooks'

export const err = false

export const useUnit = () => {

  const units = getProjectData('units','Units',[])
  return (a,b,c='u') => {
    if ( c == 'u' ) return units?.find(i=>i?.Data?.SVGID?.UnformattedValue.toString()==a)?.Data?.[b]?.UnformattedValue ?? err
    if ( c == 'f' ) return units?.find(i=>i?.Data?.SVGID?.UnformattedValue.toString()==a)?.Data?.[b]?.Value ?? err
    if ( c == 'i' ) return units?.find(i=>i?.Data?.SVGID?.UnformattedValue.toString()==a)
  }

}

export const usePick = () => {

  const picks = getProjectData('handpicked')
  return (a) => picks?.find(i=>i == a) ? true : false 

}

// Create array of sub arrays consisting of all 
// frames between each snap point (including endpoints)
export const TWEENS = (TWN, INI, SNP) => TWN?.map((i,j) => j == 0 
  ? array( i - INI   +1 , x => INI    + x )
  : array( i - SNP[j]+1 , x => SNP[j] + x )
)