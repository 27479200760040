import styled from 'styled-components/macro'
import { ReactComponent as ZoomIn } from './plus-zoom.svg'
import { ReactComponent as ZoomOut } from './minus-zoom.svg'

export const Fit = ({setMode,mode}) => {

  return ( <>
    <Box onClick={() => setMode(a => !a)}>
      { mode ? (
        <ZoomIn style={{fill:'#000',width:30,height:30,stroke:'#fff'}}/>
      ) : (
        <ZoomOut style={{fill:'#000',width:30,height:30,stroke:'#fff'}}/>
      ) }
    </Box>
  </> )

}

const Box = styled.button`
  position: absolute;
  left: 0;
  bottom: 50px;
  border-radius: 0 12px 12px 0;
  padding: 0 10px;
  background: #000;
  z-index: 1;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  border: 0;
  font-size: 40px;
  cursor: pointer;
`