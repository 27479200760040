import styled from 'styled-components/macro'

export const Image = ({data,j}) => {

  const d = data?.Floorplan?.Url
  const e = j < data?.ListOfPhotos.length ? j : 0
  const f = d ? d : data?.ListOfPhotos[e]?.Url || ''

  return (
    <img src={f} alt='' style={{display:'block'}}/>
  )
}

