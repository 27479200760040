import { useEffect }                from 'react'
import { _project }                 from 'state/store.projects'
import { _subiso }                  from 'state/store.subiso'
import { active_subiso }            from 'state/store.subiso'
import { available_subs }           from 'state/store.subiso'
import { active_project }           from 'state/store.global'
import { useFetch }                 from 'hooks/fetch/useFetch'
import { useGet, setFocus, useSet } from 'state/jotai'
import { array }                    from 'utilities/utility.misc'
import { preloadImage }             from 'utilities/utility.images'
import { ISO_FRAMES, ISO_CONFIG }   from 'api/api'
import eachOfLimit                  from 'async/eachOfLimit'
import { fn4 }                      from 'utilities/utility.misc'

export const PreloadFrames_Subs = () => {

  const { pin, pid } = useGet(active_project)
  const activesub    = useGet(active_subiso)
  const setavailsubs = useSet(available_subs)
  const file         = 'PreloadFrames_subs.jsx'
  const url          = pid ? ISO_CONFIG : null
  const config       = useFetch({ url, file }).D
  const subs         = config?.[pin]?.SUBS?.map( i => i.NAME ) ?? []
  const onsub        = subs?.includes(activesub) ?? ''
  const cache        = useGet(_subiso).status?.[pin]?.[pid].find(i => i.NAME == activesub)?.RES ?? ''

  const setFrames  = setFocus(_subiso, 'frames')
  const setPercent = setFocus(_subiso, 'percent')
  const setStatus  = setFocus(_subiso, 'status')
  const setConfig  = setFocus(_subiso, 'config')

  useEffect(()=>{
    // if ( subs ) setavailsubs(subs)
    // if ( config && onsub && pin >= 0 && !['FETCHING','DONE'].includes(cache)) {
    //   const x = config[pin].SUBS.find(i => i.NAME == activesub)
    //   const ARR = []
    //   const PID = x.SUB_FOLDER
    //   const NUM = x.IMAGE_NUM
    //   const INI = x.FIRST_IMAGE
    //   const SNP = x.IMAGE_SNAPFRAMES
    //   const EXT = x.FILE_EXT
    //   const SUB = x.PNG_PATH
    //   const FNC = x => `${ISO_FRAMES}${SUB}/${x + INI}.${EXT}`
    //   const COL = array(NUM, FNC, SNP)
    //   setConfig ( a => fn4(a,pin,pid,activesub,{'NAME':activesub,'RES':x}) )
    //   // eachOfLimit(COL, 20, 
    //   //   ( val, idx, callback ) => {
    //   //     const IMG     = preloadImage(val)
    //   //     const STATUS  = ( idx !== NUM - 1 ) ? 'FETCHING' : 'DONE'
    //   //     const PERCENT = ( idx * ( 100 / NUM ) ).toFixed() 
    //   //     ARR[idx] = { [INI+idx]:IMG }
    //   //     setFrames ( a => fn4(a,pin,pid,activesub,{'NAME':activesub,'RES':ARR     }))
    //   //     setPercent( a => fn4(a,pin,pid,activesub,{'NAME':activesub,'RES':PERCENT }))
    //   //     setStatus ( a => fn4(a,pin,pid,activesub,{'NAME':activesub,'RES':STATUS  }))
    //   //     IMG.onload = ( ) => callback()
    //   //   },
    //   //   () => {
    //   //     // console.log( 'Done preloading frames for', pin, pid, activesub )
    //   //   }
    //   // )
    // }
  },[config,onsub,activesub])
   return null
}