import styled from 'styled-components/macro'
import { Icon } from 'assets/Icons_'
import { iconLib as obj } from 'assets/iconslib'

export const IconGrid = () => {
  
  const arr = Object.keys(obj)

  return (
    <>
      <Grid>
        { arr.map( (i,j) => {
            return <Icon 
              icon={i} 
              text={i} 
              size={50} 
              key={j} 
              color={'#000'}
            />
        })}
      </Grid>
    </>
  )
}

const Grid = styled.div`
  /* border:1px solid black; */
  display: grid;
  max-width: 100%;
  min-width: 100%;
  gap: 10px;
  padding: 10px;
  grid-template-rows: repeat(auto-fit, 60px);
  grid-template-columns: repeat(auto-fit, 90px);
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;
`