import { useFetch_Highlights } from "hooks/fetch/useFetch_Highlights";
import { useGetUnit } from "hooks/useGetUnit";
import { useAtom, getFocus, useGet } from "state/jotai";
import { active_unit, active_project } from "state/store.global";
import Unit from "./Unit.model";
import { applyfilters } from 'utilities/utility.filter'
import { getProjectData } from "state/jotai.projectDataHooks";
import { _project } from 'state/store.projects';

const useActiveUnit = () => {
  const [unit] = useAtom(active_unit);
  return new Unit(unit);
};

const useUnitById = (unitId) => {
  const unit = useGetUnit(unitId);
  return new Unit(unit);
};

const useRelatedUnits = (unitId) => {
  const unit = useGetUnit(unitId);

  const raw = getProjectData('unitsraw', 'Units')

  const area = unit?.Data?.Areal?.UnformattedValue
  const price = unit?.Data?.Pris?.UnformattedValue

  const filter = {
    interval: [
      { property: 'Pris', min: price - 1000, max: price + 1000 },
      { property: 'Areal', min: area - 5, max: area + 5 },
    ],
    multiSelect: [
      { property: 'Status', values: ['UnitStatusLedig'] }
    ]
  }

  const relatedUnits = applyfilters(raw, filter)
    .filter(i => i.UnitID !== unitId)
    .slice(-4)
    .map(i => i)
  // .map( i => new Unit(i))

  return relatedUnits;
};

const useUnitHighlight = (unit) => {
  // const { highlights: h } = useFetch_Highlights();
  const { pin, pid } = useGet(active_project)
  const highlights = getFocus(_project, 'Highlights')?.[pin]?.[pid] ?? []
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? [];

  const highlightsData = highlights.map((hl) => {
    if (hl.Property === "Mobleret") {
      const value = unit.getValue(hl.Property);
      return {
        value: value === true ? buttonsLabel?.filter(btn => btn.UILabelProperty === 'Ja')[0]?.Label : buttonsLabel?.filter(btn => btn.UILabelProperty === 'Nej')[0]?.Label,
        postfix: unit.getPostfix(hl.Property),
        name: unit.getLabel(hl.Property),
        property: hl.Property,
        icon: hl.Icons
      };
    }
    return {
      value: unit.getValue(hl.Property),
      postfix: unit.getPostfix(hl.Property),
      name: unit.getLabel(hl.Property),
      property: hl.Property,
      icon: hl.Icons
    };
  })
  
  return highlightsData?.filter((f) => {
    const isMobleret = highlightsData?.filter(hd => hd.property === "Mobleret")
    // Checking if mobleret value is true or false and rendering uderam and mobleret according to it.
    if (f.property === "Uderum") {
      if (isMobleret?.[0]?.value === buttonsLabel?.filter(btn => btn.UILabelProperty === 'Ja')[0]?.Label) {

        return false
      }
    }
    if (f.property === "Mobleret") {
      if (isMobleret?.[0]?.value === buttonsLabel?.filter(btn => btn.UILabelProperty === 'Nej')[0]?.Label) {
        return false
      }
    }
    return true
  });
};

export { useActiveUnit, useUnitById, useUnitHighlight, useRelatedUnits };
