import { useState, useRef, useEffect } from 'react'
import { Box } from './Sort.style'
import { useBreak } from 'hooks/useBreak'
import { getProjectData, setProjectData } from 'state/jotai.projectDataHooks'
import { applysorting } from 'utilities/utility.sort'
import { Arrow } from './Arrow'
import { Icon } from 'assets/Icons_'
import { Foldout } from './Sort.Foldout'
import Dropdown from "components/mui/Dropdown";
import DropdownItem from "components/mui/DropdownItem";
import { getFocus, useAtom, useGet } from 'state/jotai'
import { active_project, vis_desktop_nav } from 'state/store.global'
import { _project } from 'state/store.projects'
import styled from 'styled-components'
import { Divider, useMediaQuery } from '@mui/material'
import { PoweredBy } from 'components/PoweredBy/PoweredBy'
import { Scrollbar } from 'react-scrollbars-custom'


export const Sort_RETIRED = () => {

  const [state, setState] = useState(false)
  const isDesktop = useBreak('md_up')
  const activesort = getProjectData('activesorting')
  const click = () => setState(!state)
  const [show, setShow] = useAtom(vis_desktop_nav)
  const matches = useMediaQuery('(max-width:1600px)');

  const glarr = {
    color: clr.icons,
    icon: 'globe_arrow',
    size: 10,
    wrap: {
      transform: state ? 'rotate(180deg)' : 'rotate(0deg)',
      position: 'relative',
      top: 2,
    },
  }

  return (
    <>
      <Box onClick={click} className='paragraph1-regular '>
        Sorter {isDesktop && activesort.label}<Icon {...glarr} />
      </Box>
      <Foldout state={state} setState={setState} />
    </>
  )
}

export const Sort = () => {
  const dropdownRef = useRef(null);
  const isDesktop = useBreak('md_up')
  const [state, setState] = useState(false)

  const options = getProjectData('sorteroptions', 0, [])
  const active = getProjectData('activesorting')
  const units_ = getProjectData('units')
  const raws_ = getProjectData('unitsraw')
  const click = () => setState(!state)

  const setSort = setProjectData('activesorting')
  const setUnits = setProjectData('units')
  const setRaws = setProjectData('unitsraw')
  const id = active.id
  const { Units, ...rest } = units_
  const { Units: Raws, ...wrest } = raws_
  const [selectedLabel, setSelectedLabel] = useState(active.label)

  const { pin, pid } = useGet(active_project)
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  // useEffect(()=> {
  //   setSelectedLabel(active.label)
  // }, [active])

  const handleSelect = ({ ID, Label, OrderOn, DefaultDirection }) => {
    const sorting = {
      key: OrderOn,
      direction: DefaultDirection,
      id: ID,
      label: Label,
    }

    setSelectedLabel(Label)

    const units = applysorting(Units, sorting)
    const raws = applysorting(Raws, sorting)

    setSort(sorting)
    setUnits({ ...rest, Units: units })
    setRaws({ ...wrest, Units: raws })

    dropdownRef.current.closeMenu();
  };
 

  return (

    <Box>
        <Dropdown classname="paragraph1-regular" width={isDesktop ? "300px" : "auto" } 
        ref={dropdownRef}
         buttonText={buttonsLabel?.filter(btn => btn.UILabelProperty === 'Sorter')[0]?.Label} selectedLabel={isDesktop && active?.label}
         >

        <div style={{ paddingBottom: "16px", paddingRight:"16px", paddingTop:"16px",borderBottom: "1px solid #fff", display: 'flex', justifyContent: "space-between", alignItems: "center", }}>
            <div className='h5' style={{ color: clr?.sub_text_color, width: "100%", display: 'flex', justifyContent: "center" }}>Vælg hvilken sortering du ønsker</div>
            <Box onClick={()=> dropdownRef.current.closeMenu()}className='icon-hover-effect ' >
              <Icon icon='close'  isFaIcon={true} size="xl" color={clr?.sub_text_color}  />
            </Box>
            
          </div>
          <SorterContainer isDesktop={isDesktop}>
            <Scrollbar trackYProps={{
                renderer: (props) => {
                  const { elementRef, ...restProps } = props;
                  return <span {...restProps} ref={elementRef}
                  className="trackY" />;
                },
              }}>
            {options.map((option, i) => (
             <DropdownItem
              showDivider = {options.length - 1 !== i}
               style={{ paddingLeft: "24px", paddingRight: "24px", color:"#FFF" }}
               classname="paragraph1-light"
               key={i}
               onClick={() => handleSelect(option)}
               selected={option.ID === id}
             >
               <span className='icon-hover-effect'>
               {option.Label}
               </span>
 
             </DropdownItem>
 
           ))} 
            </Scrollbar>
          </SorterContainer>
          <SorterContainerFooter>
            <div style={{paddingBottom:"6px" , paddingTop:"4px"}}>
            <PoweredBy clr='#FFF' />
            </div>
          </SorterContainerFooter>
        </Dropdown>

      {/* </DropdownBottom> */}
    </Box>
  );
};

export const SorterContainerFooter = styled.div`
  border-top: 1px solid ${clr.primary}e6;
  height: 45px;
`

export const SorterContainer = styled.div`
  overflow: hidden;
  height: ${({isDesktop}) => isDesktop ? "292px" : "274px" };
  margin-top: 32px;
`
export const DropdownBottom = styled.div`
  width: 100%;
  height: 80px;
`;
export const Bottom = styled.div`
  height: 101px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 0;
  border-top: 1px solid ${({ clr, scroll }) => scroll ? clr.primary : "none"};
`