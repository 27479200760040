import styled from 'styled-components/macro'
import { BREAK_ } from 'constants'
import { motion } from 'framer-motion'

export const Main = styled(motion.div)`
  background-color: ${({ clr }) => `${clr.primary}e6`};
  border:1px solid #fff;
  border-radius:8px;
  position: fixed;
  bottom: 54px;
  left: 15px;
  width: 220px;
  `
  export const Section = styled.div`
  display: flex;
  background-color: ${({ clr}) => `${clr?.buttons_text}bf`};
  // flex: 1;
  color: #000;
  // height: 100%;
  width: 185px;
  height: 45px;
  border-radius: 8px;
  border: 2px solid ${({ clr }) => `${clr?.buttons_text}`};
  justify-content: center;
  align-items: center;
   padding: 0 10px;
  gap: 10px;
  line-height: 1;
  cursor: pointer;
  user-select: none;
`