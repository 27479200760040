export const lineTo = (origin) => {
  if (origin === 'top-left') return 'translate(0,0)'
  if (origin === 'top-right') return 'translate(-100%,0)'
  if (origin === 'top-center') return 'translate(-50%,0)'
  if (origin === 'bottom-right') return 'translate(-100%,-100%)'
  if (origin === 'bottom-left') return 'translate(0,-100%)'
  if (origin === 'bottom-center') return 'translate(-50%,-100%)'
  if (origin === 'center') return 'translate(-50%,-50%)'
  if (origin === 'right-center') return 'translate(-100%,-50%)'
  if (origin === 'left-center') return 'translate(0,-50%)'
}