import { getFocus, setFocus, useGet, useSet } from 'state/jotai'
import { _project }                           from 'state/store.projects'
import { _bootstrap }                         from 'state/store.bootstrap'
import { active_project }                     from 'state/store.global'
import { array }                              from 'utilities/utility.misc'
import { filter_rerender }                    from 'state/store.global'
import { fn3 }                                from 'utilities/utility.misc'

export const useReset = ({ global=true } = { global:true }) => {
  
  const { pin, pid } = useGet(active_project)
  const unitsraw     = getFocus(_project  , 'unitsraw')
  const projectids   = getFocus(_bootstrap, 'projectids')

  const resetfilters    = setFocus(_project, 'activefilters')
  const resetunits      = setFocus(_project, 'units')
  const rerenderfilters = useSet(filter_rerender)

  if ( global ) {
    const num = projectids?.length || 0
    const bas = array(num, x => ({ [projectids[x]]:[] }) )
    const uni = array(num, x => ({ [projectids[x]]:unitsraw[x][arr[x]]}))
    return () => {
      resetfilters(bas)
      rerenderfilters(a=>!a)
      resetunits(uni)
    }
  } else {
    return () => {
      resetfilters(a=>fn3(a,pin,pid,{}))
      rerenderfilters(a=>!a)
      resetunits(a=>fn3(a,pin,pid,unitsraw[pin][pid]))
    }
  }

}