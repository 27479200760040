import { atom } from 'jotai'

export const _isometry = atom({
  frames        : {},
  percent       : {},
  status        : {},
  activeframe   : {},
  svgdimensions : {},
  activescale   : {},
  config        : {},
  snapindex     : {},
  imageindex    : {},
  showoverlay   : {},
  mode          : {},
  tiny          : [],
  overlays      : []
})