import { atom } from 'jotai'
import { vis_filter, vis_sorter, vis_detail } from 'state/store.global'
import { active_unit } from 'state/store.global'

export const toggle_drawer = atom(
  null,
  (get, set, update) => {
    const filter = get(vis_filter)
    const sorter = get(vis_sorter)
    const detail = get(vis_detail)
    set( vis_filter, update === 'filter' ? !filter : false )
    set( vis_sorter, update === 'sorter' ? !sorter : false )
    set( vis_detail, update === 'detail' ? !detail : false )

    if ( update === 'detail' && detail || update !== 'detail') {
      set( active_unit, {})
    }
  }
)

export const open_drawer = atom(
  null,
  (get, set, update) => {
    const filter = get(vis_filter)
    const sorter = get(vis_sorter)
    const detail = get(vis_detail)
    set( vis_filter, update === 'filter' && true )
    set( vis_sorter, update === 'sorter' && true )
    set( vis_detail, update === 'detail' && true )

    if ( update !== 'detail' ) {
      set( active_unit, {})
    }
  }
)

export const close_drawer = atom(
  null,
  (get, set, _) => {
    set( vis_filter, false )
    set( vis_sorter, false )
    set( vis_detail, false )
    set( active_unit, {})
  }
)

export const reset_unit = atom(
  null,
  (_, set, _e) => {
    set( active_unit, {})
  }
)