import { atom } from 'jotai'

export const _map = atom({
  views       : [],
  animations  : {},
  initial     : null,
  activeview  : null,
  dimensions  : null,
  percent     : 0,
  status      : null,
  config      : [],
  activescale : null,
  ready       : false,
  svgs        : [],
  initialmode : null,
  hover       : {}
})