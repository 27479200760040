import styled from 'styled-components/macro'
import { MENU, BREAK_ } from 'constants'
import { sb } from 'styles/styles.scrollbar'

export const Content = styled.main`
  overflow: hidden;
  ${ ({styles}) => styles }
  ${ BREAK_.md_dn } {
    ${ ({styles}) => styles }
  }
  ${ BREAK_.md_up } {
    padding-right: ${ ({right}) => right ? MENU.DESKTOP_UNIT_DRAWER_W : 0 }px;
  };
  transition: 
    padding-left 0.3s ease, 
    padding-right 0.3s ease,
    margin-top 0.3s ease,
    margin-left 0.3s ease,
    height 0.3s ease, 
    width 0.3s ease;
`

export const Root = styled.div`
  isolation: isolate;
  width: 100%;
  height: 100%;
  overflow-y:scroll;
  ${ ({styles}) => styles }
  ${ sb.hide };
`