import { LottiPlay } from "components/LottieAnimation/LottiePlay";
import { useCachedImages } from "hooks/useCachedImages";
import useIntersectionObserver from "hooks/useIntersectionObserver";
import { useAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import { prev_img } from "state/store.global";

export const ImgWrapper = ({ img, func, style, imgStyle, alt, loader, onClick, bgLoad = false }) => {
  
  // const [pImg, setP_img]             = useAtom(prev_img)
  // const [imgSrc, setImgSrc] = useState('')
  // const [loading, setLoading]        = useState(true)
  const imgRef                       = useRef()
  const [ref, isIntersecting] = useIntersectionObserver({ threshold: 0.1 });
  const cachedImage                  = useCachedImages(isIntersecting || bgLoad, img);
  // console.log({img})
  const imageLoaded                  = () => setLoading(false)

  // useEffect(()=> {
  //   let timeout;
  //   if(imgSrc !== img) setLoading(true)
  //   else if (imgSrc === img) {
  //     timeout = setTimeout(()=> setLoading(false), 1500) 
  //   }
  //   setImgSrc(img)
  //   return () => clearTimeout(timeout)
  // }, [img, imgSrc])

  return (
  <div ref={ref} style={{ position: "relative", width: '100%', height: '100%', ...style,  }}>
      {cachedImage ? (
        <img
        style={{ display: 'block', width: '100%', height: '100%', ...imgStyle }}
          src={cachedImage}
          loading="lazy"
          alt={alt}
          onClick={onClick}
        />
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: "100%",
            width: "100%",
          }}
          onClick={onClick}
        >
          <LottiPlay style={{ height: "100%" }} loop={true} />
        </div>
      )}
    </div>
    )
}


  // default props
ImgWrapper.defaultProps = {
    func   : () => {},
    onClick: () => {},
    alt    : '',
    loader : true
};
 