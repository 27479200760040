import { List, Main }     from 'components/List/Card/Card.style'
import { Content }        from 'components/List/Card/Card.Content'
import { get, floorplan } from 'components/List/List.Utilities'
import { FLOORPLAN }      from 'api/api'
import { active_unit, vis_desktop_nav }    from 'state/store.global'
import { useAtom }        from 'state/jotai'
import { Box } from '@mui/material'
import { useState } from 'react'

export const Card = ({ units, extra, pick, unitErrMsg, click, mode, snackbarOpen, setSnackbarOpen, snackbarMessage, setSnackbarMessage, handleSnackbarOpen, handleSnackbarClose }) => {
  const active = useAtom(active_unit)[0]
  const [show, setShow] = useAtom(vis_desktop_nav);

  return (
    <>
    {
      !units.length ? <Box sx={{textAlign: 'center'}}>{unitErrMsg}</Box>
      :
     <List>
     {
       extra?.map((i, j) => {
         const onClick   = () => click(i.UnitID)
         const thumbnail = floorplan(i, FLOORPLAN)
         const card      = { key:i?.UnitID, style:pick, onClick }
         const content   = { unit:i, thumbnail, color:get(i,'UnitColor'),snackbarOpen, setSnackbarOpen, snackbarMessage, setSnackbarMessage, handleSnackbarOpen, handleSnackbarClose}
         const isActive = active.UnitID === i.UnitID
         const color = isActive ? {background:`${get(i,'UnitColor')}66`} : {}

         return (
           <Main {...card} color={get(i,'UnitColor')} style={color}>
             <Content {...content} />
           </Main>
         )
     })}
     {
       units?.map((i, j) => {
        const onClick   = () => {click(i.UnitID); setShow(false);}
        const thumbnail = floorplan(i, FLOORPLAN)
         const card      = { key: i?.UnitID, onClick }
         const content   = { unit:i, thumbnail, color:get(i,'UnitColor'), mode, snackbarOpen, setSnackbarOpen, snackbarMessage, setSnackbarMessage, handleSnackbarOpen, handleSnackbarClose}

         const isActive = active.UnitID === i.UnitID
         const color = isActive ? {background:`${get(i,'UnitColor')}66`} : {}
         
         return (
           <Main {...card} color={get(i,'UnitColor')} style={color}>
             <Content {...content} />
           </Main>
         )
     })}
   </List>
    }
      {/* <div style={{ height: 10, background: '#fff' }} /> */}
    </>
  )
}
