import styled, { keyframes } from "styled-components";

const scrolling = keyframes`
    0% {opacity: 0}
    100% {opacity: 1}
`

export const ScrollParent = styled.div`
    opacity: 0;
    animation: ${scrolling} 0.3s linear forwards;
`
export const NotScrollParent = styled.div`
    opacity: 0;
    animation: ${scrolling} 0.3s linear forwards;
`
export const SwiperBox = styled.div`
    opacity: 0;
    animation: ${scrolling} 0.3s linear forwards;
`