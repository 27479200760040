import { useEffect }    from 'react'
import { useSnapLogic } from 'components/Isometry/Isometry.SnapLogic'
import { getFocus, setFocus, useGet, useSet }       from 'state/jotai'
import { active_project, vis_overlay }  from 'state/store.global'
import { TWEENS }       from 'components/Isometry/Isometry.utilities'
import { _isometry } from 'state/store.isometry'
import { _bootstrap } from 'state/store.bootstrap'

export const SnapOnRotationEnd = ({ frame, setFrame, config, setIndex, rmode, index }) => {
  const SNP = config?.IMAGE_SNAPFRAMES
  const INI = config?.FIRST_IMAGE
  const TWN = SNP?.length > 1 ? SNP?.slice(1) : SNP
  const tweens = TWEENS(TWN, INI, SNP) ?? []
  const setShow = useSet(vis_overlay)
  const pids   = useGet(_bootstrap).projectids
  const allConfig     = getFocus(_isometry, 'config')
  
  const setFrameFocus = setFocus(_isometry, 'activeframe')
  const { pin, pid } = useGet(active_project) 

  
  useEffect(()=>{
    if ( !rmode ) {
      useSnapLogic({ value: frame, setFrame, tweens, setShow, setIndex, SNP, setFrameFocus, pids, allConfig, pin })
    }
  },[rmode,SNP])

  return null
}