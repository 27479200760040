import { Main }   from './Help.style'
import { Box }    from './Help.style'
import { Double } from './Help.style'
import { Wrap }   from './Help.style'

import { Icon } from 'assets/Icons_'

import { get } from 'state/jotai'
import { _project } from 'state/store.projects'
import { Link } from "react-router-dom"

import { static_assets } from 'state/store.global'
import { useAtomValue } from 'state/jotai'
import { useTranslation } from 'react-i18next'
export const Help = () => {
  
  const assets = useAtomValue(static_assets)
  const obj     = assets[`realtor_2`]
  const gallery = get(_project, 'gallery')
  const photos  = gallery?.Data?.ListOfPhotos ?? []
  const tags    = gallery?.Tags ?? []  
  
  const { i18n:{language} } = useTranslation()

  return (
    <>
      <div style={{flex:'0 0 38px'}}/>
      <Main>
        <Wrap>
          <Box clr={clr}>
            <p>Få en <Link to='/interactive'>interaktiv rundtur</Link> i boligvælgeren og se hvordan du mest effektivt finder din drømmebolig</p>
            <span style={{position:'absolute',bottom:5,right:25}}>
              <Icon icon='arrow_right2' color='#000' size='38'/>
            </span>
          </Box>
          <Box clr={clr} img={photos?.[5]?.Url} type='top'>
            <p>Download <Link to={`/${language}/downloads`}>salgsmateriale</Link></p>
            <span style={{position:'absolute',bottom:5,right:25}}>
              <Icon icon='arrow_right2' color='#000' size='38'/>
            </span>
          </Box>
          <Box clr={clr}>
            <p>Salget starter snart. Du kan komme foran i køen ved at <Link to='/contact'>skrive dig op</Link> til præmieren her</p>
            <span style={{position:'absolute',bottom:5,right:25}}>
              <Icon icon='arrow_right2' color='#000' size='38'/>
            </span>
          </Box>
          <Box clr={clr} img={photos?.[15]?.Url} type='top'>
            <p>Se <Link to={`/${language}/gallery`}>galleri</Link></p>
            <span style={{position:'absolute',bottom:5,right:25}}>
              <Icon icon='arrow_right2' color='#000' size='38'/>
            </span>
          </Box>
        </Wrap>
        <Wrap>
          <Double img={photos?.[2]?.Url}></Double>
          <Double>
            <p>Udlejningen varetages af</p>
            <div style={{margin:'20px 0',border:'0px solid black',fontSize:'2rem',fontWeight:400,lineHeight:1.5}}>
              <p>UNITY Kista Campus</p>
              <p>Jyllandsgaten 138</p>
              <p>164 42 Kista</p>
              {/* <p>+45 12345678</p> */}
              <p><a style={{textDecoration:'none',color:'#000'}}href='info.kistacampus@unity-living.com'>info.kistacampus@unity-living.com</a></p>
            </div>
            <div style={{height:20}}/>
            <img src={obj.src} alt='' width='230'/>
          </Double>
        </Wrap>
        <div style={{flex:'0 0 100px'}}/>
      </Main>
    </>
  )
}