import styled from 'styled-components/macro'
import { BREAK_ } from 'constants'

export const Info = ({data}) => {

  const a = data.UnitStatusName.UnformattedValue 
  const b = data.Adresse.Value
  const c = data.ZipcodeID.Value
  const d = data.By.Value
  const e = data.UnitColor.Value

  return (
    <Container>
      <div style={{color:e,fontWeight:600}}>{ a }</div>
      <div>{ b }</div>
      <div>{ `${c} ${d}` }</div>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  padding: 10px 10%;
  background: #f7f7f7;
  font-size: 1.0625rem;
  ${ BREAK_.md_dn } { 
    font-size:0.75rem;
  }
  /* font-weight: 500; */
  display:flex;
  flex-direction:column;
  gap:2px;
`