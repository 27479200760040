import styled          from 'styled-components/macro'
import { useNavigate } from 'react-router-dom'

// import { lib } from 'assets/icons_lib'

// import { TopNav }      from 'components/TopNav/TopNav'
// import { NavMobile }   from 'components/Nav/Nav.Mobile'
// import { DualMenu }    from 'components/DualMenu/DualMenu'
// import { IconGrid }    from 'components/IconGrid/IconGrid'
// import { Button }      from 'components/Button/Button'

export const ViewFrontpage = () => {

  const navigateTo = useNavigate()

  return (
    <div style={{overflowY:'auto',overflowX:'hidden', width:'100%'}}>
    {/* <Sticky> */}
      {/* <NavMobile /> */}
    {/* </Sticky> */}
    {/* <TopNav/> */}
    {/* <MenuPoly/> */}
    
    {/* { console.log( lib.cross ) } */}

    {/* <div style={{display:'flex',gap:20,padding:20}}>
      <Button click={()=>navigateTo('/oversigt/luftfoto')}>Oversigtskort</Button>
    </div> */}

    {/* <div style={{display:'flex',gap:20,padding:20}}>
      <Button click={()=>navigateTo('/bygning1/isometri')}>Bygning1 - Isometri</Button>
      <Button click={()=>navigateTo('/bygning1/listevisning')}>Bygning1 - Liste</Button>
    </div> */}

    {/* <div style={{display:'flex',gap:20,padding:20}}>
      <Button click={()=>navigateTo('/bygning2/isometri')}>Bygning2 - Isometri</Button>
      <Button click={()=>navigateTo('/bygning2/listevisning')}>Bygning2 - Liste</Button>
    </div> */}

    {/* <div style={{display:'flex',gap:20,padding:20}}>
      <Button click={()=>navigateTo('/bygning3/isometri')}>Bygning3 - Isometri</Button>
      <Button click={()=>navigateTo('/bygning3/listevisning')}>Bygning3 - Liste</Button>
    </div> */}

    {/* <IconGrid /> */}

    </div>
  )
  
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  isolation: isolate;
  z-index: 0;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  ::-webkit-scrollbar { display: none; }
`
const Space = styled.div`
  width:${({h})=>h?h:0}px;
  height:${({v})=>v?v:0}px;
`

const Sticky = styled.div`
  width:100%;
  position:sticky;
  top:0;
`