import { applysorting } from 'utilities/utility.sort'
import { Option } from './Sort.style'
import { getProjectData, setProjectData } from 'state/jotai.projectDataHooks'

export const Options = () => {
  
  const options = getProjectData('sorteroptions')
  const active = getProjectData('activesorting')
  const units_ = getProjectData('units')
  const raws_ = getProjectData('unitsraw')

  const setSort = setProjectData('activesorting')
  const setUnits = setProjectData('units')
  const setRaws = setProjectData('unitsraw')

  const id = active.id
  const { Units, ...rest } = units_
  const { Units: Raws, ...wrest } = raws_

  return (
    <>
      {
        options.map(({ ID, Label, OrderOn, DefaultDirection }) => {
        
          const sorting = {
            key: OrderOn,
            direction: DefaultDirection,
            id: ID,
            label: Label,
          }

          const click = () => {
            const units = applysorting(Units, sorting)
            const raws = applysorting(Raws, sorting)
            setSort(sorting)
            setUnits({ ...rest, Units: units })
            setRaws({ ...wrest, Units: raws })
          }

          return (
            <Option clr={clr} active={ID === id} key={ID} onClick={click}>
              {Label}
            </Option>
          )
      })}
    </>
  )
}
