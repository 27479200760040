import { Helmet }           from 'react-helmet-async'
import { static_assets }    from 'state/store.global'
import { _bootstrap }       from 'state/store.bootstrap'
import { getFocus, useGet } from 'state/jotai'
import { useFetch_Colors }  from 'hooks/fetch/useFetch_Colors'

export const Head = () => {

  const title = getFocus(_bootstrap, 'sitename')
  const fav   = useGet(static_assets)?.['favicon_ico']
  const clr   = useFetch_Colors().clr

  return <>
    <Helmet>
      <title>{ title }</title>
      <link rel="icon" type="image/png" href={fav?.src} sizes="16x16" />
      <meta name="theme-color" content={clr?.primary}/>
      <meta name="description" content="EIDO"></meta>
    </Helmet>
  </>

}


// {/* <link rel="preload" href="http://localhost:9000/terrasserne/resources/logos/logo_desktop.svg" as="image" type="image/svg+xml"/> */}
// {/* <link rel="preload" href="http://localhost:9000/terrasserne/resources/logos/logo_mobile.svg" as="image" type="image/svg+xml"/> */}
// {/* <link rel="manifest" href="%PUBLIC_URL%/manifest.json" /> */}
// {/* <script id="CookieConsent" src="https://policy.app.cookieinformation.com/uc.js" data-culture="DA" type="text/javascript"></script> */}