import styled from 'styled-components/macro'

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow: hidden;
  /* border:1px solid black; */
`
export const Row = styled.div`
  display: flex;
  cursor: pointer;
  background-color: ${ ({color}) => (() => color + '33' )() };
  cursor:pointer;
  :hover { background-color: ${ ({color}) => (() => color + '66' )() }; }
  align-items:center;
  /* :nth-of-type(even){ background-color:#eee; } */
  /* :hover { background-color:#ccc; color:#fff; } */
`
export const Cell = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-basis: ${({ w }) => (w ? `${w}px` : 'unset')};
  flex-grow: ${({ w }) => (w ? 'unset' : 1)};
  flex-shrink: ${({ w }) => (w ? 0 : 'unset')};
  font-size: 0.75rem;
  /* overflow: hidden; */
  align-items: center;
  justify-content: center;
  height: 30px;
  line-height:30px;
  /* border: 1px solid black; */
`
export const HeaderCell = styled(Cell)`
  background: #aaa;
  color: white;
`
export const HeaderRow = styled(Row)`
  border-bottom:2px solid transparent;
  overflow:hidden;
`
export const circ = (d) => ({
  background:d,
  display:'flex',
  height:'10px',
  width:'10px',
  borderRadius:'100%',
  border:'1px solid black'
})
export const td = {
  overflow:'hidden',
  textOverflow:'ellipsis',
  whiteSpace:'nowrap',
  width:'100%',
  padding:'0 5px',
  lineHeight:1,
}