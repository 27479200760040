import { Col, Row, Button } from './Filter.Controls.style'

export const Boolean = ({Label,PossibleValues}) => {

  return (
    <Col key={Label}>

      { Label }

      <Row>



      </Row>

    </Col>

  )

}