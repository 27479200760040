import { css } from 'styled-components'
import { sb } from './styles.scrollbar'

export const html = css`  
  html {
    -webkit-text-size-adjust: 100%;
    -webkit-overflow-scrolling: touch; /* enables “momentum” (smooth) scrolling */
    -moz-tab-size: 4;
    // overscroll-behavior: none;
    ${'' /* overflow-y: scroll; */}
    // overflow:hidden;
    ${ sb.hide };
    height: 100%;
    width: 100%;
    tab-size: 4;
    font-size: 62.5%;
    ${'' /* position:fixed; */}
    ${'' /* border: 10px solid pink; */}
  }
`