import { useEffect } from 'react'
import { useFetch }  from 'hooks/fetch/useFetch'
import { COLORS }    from 'api/api'

export const useFetch_Colors = () => {

  const { D:clr } = useFetch({url:COLORS,file:'useColors.jsx'})
  const res = Object.keys(clr || {}).length ? true : false

  useEffect(()=>{
    if ( clr ) {
      window.clr = { ...clr }
    }
  },[clr])

  return { clr, res }

}