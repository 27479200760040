import axios from 'axios'
import { BENEFIT } from 'api/api'
import { useEffect, useState } from 'react'
import { _project } from 'state/store.projects'
import { _bootstrap } from 'state/store.bootstrap'
import { active_project } from 'state/store.global'
import { active_unit } from 'state/store.global'
import { housing_benefit } from 'state/store.global'
import { gross_income } from 'state/store.global'
import { useGet, getFocus } from 'state/jotai'

const formatNumber = num => { return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') }

export const useHousingBenefit = () => {
  
  const { pid:projectID } = useGet(active_project)
  const SiteID            = getFocus(_bootstrap, 'siteid')
  const Income            = useGet(gross_income).UnformattedValue
  const UnitID            = useGet(active_unit).UnitID
  // const [benefit,setBenefit] = useAtom(housing_benefit)
  const [benefit, setBenefit] = useState(null)
  
  useEffect(()=>{
    if ( SiteID && UnitID && Income ) {
      const Url = BENEFIT(projectID, UnitID, Income, SiteID )
      const Callback = res => setBenefit(res.data)
      HousingBenefitFetcher(Url,Callback)
    }
  },[SiteID,UnitID,Income])

  if ( benefit ) return formatNumber(benefit.BenefitAmountMonthly) + ',00 kr.'
  return false

}


export const HousingBenefitFetcher = (url, callback) => {
  axios
    .get(url)
    .then(callback)
    .catch(err => console.log(err))
  return false
}