export const Arrow = ({ pin, type, isIsometry, D, fill, w=25, click, style,percent }) => {

  
  const R = 'm 23.534619,13.096857 c 0.172699,-0.193035 0.347486,-0.581504 0.351666,-0.972364 0.0041,-0.390861 -0.162243,-0.784119 -0.330765,-0.981943 L 14.282862,0.6530352 C 13.777309,0.0595601 12.92427,0.04758891 12.406175,0.62669867 11.888081,1.2058082 11.877631,2.1829621 12.383184,2.7764374 L 19.294503,10.691833 1.3806743,10.440439 C 0.52972484,10.233037 0.00954068,11.007579 0.00118018,11.789301 -0.0071803,12.571022 0.4962829,13.359929 1.3493223,13.3719 l 18.0844367,0.253789 -7.078528,7.719069 c -0.518094,0.579108 -0.528544,1.556263 -0.02299,2.149737 0.505553,0.593476 1.358593,0.605447 1.876687,0.02632 z'
  const L = 'm 23.534619,13.096857 c 0.172699,-0.193035 0.347486,-0.581504 0.351666,-0.972364 0.0041,-0.390861 -0.162243,-0.784119 -0.330765,-0.981943 L 14.282862,0.6530352 C 13.777309,0.0595601 12.92427,0.04758891 12.406175,0.62669867 11.888081,1.2058082 11.877631,2.1829621 12.383184,2.7764374 L 19.294503,10.691833 1.3806743,10.440439 C 0.52972484,10.233037 0.00954068,11.007579 0.00118018,11.789301 -0.0071803,12.571022 0.4962829,13.359929 1.3493223,13.3719 l 18.0844367,0.253789 -7.078528,7.719069 c -0.518094,0.579108 -0.528544,1.556263 -0.02299,2.149737 0.505553,0.593476 1.358593,0.605447 1.876687,0.02632 z'
  const STY = {
    width: w,
    cursor: 'pointer',
    zIndex: 0,
    transform: D === 'L' ? 'rotate(180deg)' : undefined,
    ...style,
  }

  return (
    <>
    {
        percent == 100 && 
      <svg 
        onClick={click} 
        style={STY}
        viewBox="0 0 24 24" 
        width={w}
        xmlns="http://www.w3.org/2000/svg"
      
      >
        <path d={D == 'R' ? R : L } fill={fill}/>
      </svg>
      }
    </>
  )

}