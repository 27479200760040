import { atom, useAtom } from "jotai";

const isOpenContactDrawer = atom(false);
const contactDrawerUnit = atom(null);

const useContactDrawer = () => {
  const [isOpen, setIsOpen] = useAtom(isOpenContactDrawer);
  const [unit, setUnit] = useAtom(contactDrawerUnit);

  const toggle = () => {
    if (isOpen) {
      // set null if closed.
      setUnit(null);
    }

    setIsOpen(!isOpen);
  };

  const open = (unit) => {
    setUnit(unit);
    setIsOpen(true);
  };

  return { toggle, open, isOpen, unit };
};

export { useContactDrawer };
