import styled from 'styled-components/macro'

export const B1 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
  border-radius: 99px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  `
export const B2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`
export const Bar = styled.div`
  border: 2px solid #000;
  width: 300px;
  height: 25px;
  border-radius: 99px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 0.75rem;
  background: darkgray;
  line-height: 1;
`
export const Progress = styled.div`
  background: #000;
  height: 25px;
  width: ${p => p.pct * 3}px;
  position: absolute;
  top: 0;
  left: 0;
`
export const Percent = styled.div`
  position: absolute;
`