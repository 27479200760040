import styled from 'styled-components/macro'
import { BREAK_ } from 'constants'

export const Price = ({data}) => {

  const a = data?.Husleje?.Value
  const b = data?.Husleje?.Postfix

  const c = a == '-' ? a : `${a} ${b}`

  return (
    <Container>
      { `${ c }`}
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  padding: 10px;
  text-align: center;
  font-size: 1.375rem;
  ${BREAK_.md_dn} {
    font-size: 0.875rem;
  }
  font-weight: 600;
  background: #f7f7f7;
`