import { 
  axios_mock, 
  axios_live 
} from 'utilities/axios.instances'

//━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
export const fetcher_mock = (...args) =>
  axios_mock.get(...args)
    .then(res => res.data )
    .catch(err => { errLog(args,err) })

//━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
export const fetcher = (...args) => 
  axios_live.get(...args)
    .then(res => res.data )
    .catch(err => errLog(args,err))

//━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
const errLog = ( args, err ) => { console.log(
    '━━━━━━━━━━━━' 
  + '\nAxios_Error'
  + '\n' + args
  + '\n' + err
  + '\n━━━━━━━━━━━━\n'
)}