import { useRef, useState } from "react";
import { comparesData, compares, active_project } from "state/store.global";
import { getFocus, useGet, useSet } from "state/jotai";
import { FreeMode } from "swiper";
import "swiper/css/free-mode";
import { _project } from "state/store.projects";
import Cards from "components/Compare2/Compare.cards";
import PropertyCard from "components/Compare2/Compare.propertyCards";

export const Compare = ({showDiff, passRef, isScrolled, setIsScrolled }) => {
  const cardRef     = useRef();
  const propertyRef = useRef();

  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];
  const data         = useGet(comparesData);
  const comps        = useGet(compares);
  const setComps     = useSet(compares);
  const units        = data?.Units ?? [];
  
  const x          = comparePropertyNames(units);
  let   GroupNames = []

  const handleScroll = (e, type) => {
    if (type === "swiper") {
      // Sync scroll from `passRef` to `propertyRef`
      if (propertyRef.current && propertyRef.current.customType !== "normalSwiper") {
        propertyRef.current.customType = "swiper";
        propertyRef.current.scrollLeft = Math.abs(e.translate);
      }
      // Reset `customType` after a short delay to avoid endless triggering
      setTimeout(() => {
        if (propertyRef.current) {
          propertyRef.current.customType = null;
        }
      }, 0);
    } else if (type === "normalSwiper") {
      // Sync scroll from `propertyRef` to `passRef` and `cardRef`
      if (passRef.current && passRef.current.customType !== "swiper") {
        passRef.current.customType = "normalSwiper";
        const scrollLeft = e.target.scrollLeft;
        passRef.current.swiper.translateTo(-scrollLeft, 0);
        if (cardRef.current && cardRef.current.swiper) {
          cardRef.current.swiper.translateTo(-scrollLeft, 0);
        }
      }
      // Reset `customType` after a short delay to avoid endless triggering
      setTimeout(() => {
        if (passRef.current) {
          passRef.current.customType = null;
        }
        if (cardRef.current) {
          cardRef.current.customType = null;
        }
      }, 0);
    }
  };
  

  if(units?.length) {
    GroupNames = units?.[0]?.Groups;
  }

  const props = { units, createGroupArr, x, buttonsLabel, GroupNames, showDiff, handleScroll, cardRef, passRef, isScrolled, setIsScrolled, propertyRef }
  return (
    <>
      <Cards { ...props } />
      <PropertyCard { ...props } />
    </>
  );
};

const SwiperWrapStyle = {
  width     : "100%",
  height    : "100%",
  overflow  : "initial",
  userSelect: "none",
};

const SwiperProps = {
  spaceBetween: 4,
  modules     : [FreeMode],
  freeMode    : {
    enabled       : true,
    sticky        : true,
    momentumBounce: false,
    momentum      : false,
  },
    // breakpoints: {
    //   580: { spaceBetween: 30, slidesPerView: 4, allowSlideNext: true },
    //   768: { spaceBetween: 30, slidesPerView: 4, allowSlideNext: true },
    //   1058: { spaceBetween: 30, slidesPerView: 4, allowSlideNext: true },
    //   1558: { spaceBetween: 30, slidesPerView: 4, allowSlideNext: true },
    // },
  slidesPerView: 'auto',
  style        : SwiperWrapStyle,
  grabCursor   : true,
    // centeredSlidesBounds: true,
    // centerInsufficientSlides: true,
 
  keyboard       : { enabled: true, onlyInViewport: false },
  resistance     : true,
  resistanceRatio: 0.0,
};

const createGroupArr = ({ i, comparePropertyNames }) => {
  const groupArr = comparePropertyNames.reduce((a, k) => {
    const itemData = i.Data[k.Property] ?? {
      Label      : "-",
      Value      : "",
      IsDifferent: k.Different,
    };
    const groupName = i.Data[k.Property]?.GroupName ?? k.Group;
      // if a doesn't contain current GroupName yet, add it!
    if (!a[groupName]) {
      a[groupName] = [];
    }
      // add current itemData to GroupName Array under certain conditions
    a[groupName].push(itemData);
    return a;
  }, {});
  return groupArr;
};

const comparePropertyNames = (units) => {
  let arr = [];
  units.forEach((unit) => {
    Object.keys(unit.Data).forEach((propertyName) => {
      const hasLabel      = unit.Data[propertyName].Label;
      const isComparable  = unit.Data[propertyName].Comparable;
      const shouldInclude = hasLabel && isComparable;
      const exists        = arr.map((i) => i.Property).includes(propertyName);
      if (!exists && shouldInclude) {
        arr = [
          ...arr,
          {
            Property : propertyName,
            Label    : unit.Data[propertyName].Label,
            Group    : unit.Data[propertyName].GroupName,
            Different: unit.Data[propertyName].IsDifferent,
          },
        ];
      }
    });
  });
  return arr;
};
