import { Box } from "@mui/material";
import ContactDrawer from "components/Contact/ContactDrawer";
import React from "react";

const ViewContact = () => {
  return (
    <Box height="100%" sx={{ overflowY: "auto" }}>
      <ContactDrawer />
    </Box>
  );
};

export default ViewContact;
