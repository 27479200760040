import styled              from 'styled-components/macro'
import { Map }             from 'components/Map/Map'
import { AnimatePresence } from 'framer-motion'

export const ViewMap = () => {
  
  return (
    <>
      {/* <AnimatePresence> */}
        <Container>
          <Map/>
        </Container>
        {/* <div style={{border:'10px solid orange',height:100,width:'100%'}}></div>  */}
      {/* </AnimatePresence> */}
    </>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
  ::-webkit-scrollbar { display: none; }
`