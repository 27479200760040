export const comparePropertyNames = (comps) => {
  let arr = []
  comps.forEach( unit => {
    Object.keys(unit.Data).forEach( (propertyName) => {
      const hasLabel  = unit.Data[propertyName].Label
      const isComparable = unit.Data[propertyName].Comparable
      const shouldInclude = hasLabel && isComparable
      const exists = arr.map(i => i.Property ).includes( propertyName )
      if ( !exists && shouldInclude ) {
          arr = [...arr, {
            Property  : propertyName,
            Label     : unit.Data[propertyName].Label,
            Group     : unit.Data[propertyName].GroupName,
            Different : unit.Data[propertyName]
          }]
        }
      })
    })
    // console.log( 'comparePropertyNames:', arr )
    return arr
}

export const createGroupArr = (data,comparePropertyNames) => {
  const groupArr = comparePropertyNames.reduce( (a,k) => {
    const itemData = data[k.Property] ?? { 
      // Label : k.Label, 
      Label : '-', 
      Value : '',
      IsDifferent: k.Different
    }
    const groupName = data[k.Property]?.GroupName ?? k.Group
    // if a doesn't contain current GroupName yet, add it!
    if ( !a[groupName] ) { a[groupName] = [] }
    // add current itemData to GroupName Array under 
    // certain conditions
    a[groupName].push(itemData)
    return a
  },{})
  return groupArr
}

export const groupNamesArr = (comps) => {
  let arr = []
  comps.forEach( unit => {
    unit.Groups.forEach( group => {
      if ( !arr.includes(group) )
        arr = [...arr, group ]     
    })
  })
  return arr
}