import { css } from 'styled-components'

// TODO: fix scrollbar management
export const sb = {
  hide : css`
    ::-webkit-scrollbar { display:none; }
    scrollbar-width: none;
  `,
  show : css`
    ::-webkit-scrollbar { width:5px; }
    ::-webkit-scrollbar-track { background-color:transparent; }
    ::-webkit-scrollbar-thumb { background-color:#ccc; border-radius:20px; }
    ::-webkit-scrollbar-thumb:hover { background:#aaa; }
    scrollbar-color: lightgray white;
    scrollbar-width: thin;
  `
}

export const scrollbar = css`
  body {
    ${ sb.show }
  }
`