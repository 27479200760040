import { useEffect }        from 'react'
import { useFetch }         from 'hooks/fetch/useFetch'
import { setFocus, useGet } from 'state/jotai'
import { _isometry }        from 'state/store.isometry'
import { renderLayout }     from 'state/store.global'
import { ISO_CONFIG }       from 'api/api'
import eachOfLimit          from 'async/eachOfLimit'
import axios                from 'axios'
import { fn3 }              from 'utilities/utility.misc'

export const PreloadSvg_Overlays = () => {

  const file        = 'main/Overlays/Overlays.jsx'
  const url         = ISO_CONFIG
  const config      = useFetch({ url, file }).D
  const ready       = useGet(renderLayout)
  const setOverlays = setFocus(_isometry, 'overlays')

  useEffect(()=>{

    if ( config && ready ) {
      
      config.forEach((i,j) => {
        const pin  = j
        const pid  = config[j].PROJECT_ID
        const path = config[j].SVG_JSON_PATH
        const arr  = config[j].IMAGE_SNAPFRAMES
        const col  = arr.map( (i,j) => `${path}${i}.json` )
        const ovr  = {}
        eachOfLimit(col, col.length, 
          ( val, idx, callback ) => {
            axios.get(val).then((res)=>{
              ovr[ arr[idx] ] = res.data
              setOverlays(a => fn3(a,pin,pid,ovr) )
              callback()
            })
          },
          () => {
            // console.log( 'Done preloading svg overlays for ', pin, pid )
          }
        )
      })
    }
  },[config, ready])

}