import { createGlobalStyle } from "styled-components";
import { html } from "./styles.html";
import { body } from "./styles.body";
import { font } from "./styles.font";
import { scrollbar } from "./styles.scrollbar";
import { all } from "./styles.all";
import { root } from "./styles.root";

export const GlobalStyle = createGlobalStyle`

/*${all};
  ${html};
  ${body};
  ${font};
  ${scrollbar}*/
  ${root};

  /* Avoid text overflows */
  /* p, h1, h2, h3, h4, h5, h6 { overflow-wrap: break-word; } */
  /* a { transition: color 0.3s; } */
  /* img { height:auto; max-width:100%; } */
`;
