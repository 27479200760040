import { useState } from 'react'
import { Icon }            from 'assets/Icons_'
import styled              from 'styled-components/macro'
import { BREAK_ }          from 'constants'
import { Portal }          from 'components/Portal/Portal'
import { Backdrop }        from 'styles/styles.backdrop'
import { AnimatePresence } from 'framer-motion'
import { useBreak }        from 'hooks/useBreak'
import { motion }          from 'framer-motion'
import { ColorLegend }     from 'components/ColorLegend/ColorLegend'
import { DesktopColorLegend }     from 'components/ColorLegend/DesktopColorLegend'

export const Status = ({percent,state,setState,isMobile,pin}) => {
  // const isMobile = useBreak('md_dn')

  // const [state, setState] = useState(false)

  // const animation = {
  //   initial    : { opacity:0 },
  //   animate    : { opacity:1 },
  //   exit       : { opacity:0 },
  //   transition : { duration:0.5, ease:'linear' },
  // }


  return (
    <>
      <Wrap onClick={()=>  isMobile && setState(true)} >
        <Icon icon='colortray' size={ isMobile ? 20 : 24 } color={isMobile ? "#000" : clr?.primary_text}/>
        <div className="eus-button-2"  style={{ color: isMobile ? "#000":clr?.primary_text }}>Status</div>
      </Wrap>
        <AnimatePresence>
          {
            state && (
              <>
                <DesktopColorLegend />
              </>
            )
          }
        </AnimatePresence>
    </>
  )

}

const Wrap = styled.div`
  top: calc(100% - 205px);
  left: 40px;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 15px;
  height: 32px;
  cursor: pointer;
  line-height: 1;
  ${BREAK_.md_dn} {
    font-size: 17px;
    left: 20px;
    top: calc(100% - 140px);
  }
`