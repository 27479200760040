import styled       from 'styled-components/macro'

import { Help }     from 'components/Help/Help'
import { Help_ }    from 'components/Help/Help_'
import { DualMenu } from 'components/DualMenu/DualMenu'

import { useBreak } from 'hooks/useBreak'

export const ViewHelp = () => {
  
  const isMobile = useBreak('md_dn')

  return (
    <>
      {
        isMobile ? (
          <Help_/>
        ) : (
          <Help/>
        )
      }
    </>
  )
}