import { Backdrop, Container, Top, Main, Col } from './Compare.Portal.style'

import { useState }      from 'react'
import { useBreak }      from 'hooks/useBreak'
import { _project }      from 'state/store.projects'
import { useGet }        from 'state/jotai'
import { comparesData }  from 'state/store.global'
import { Portal }        from 'components/Portal/Portal'
import { Icon }          from 'assets/Icons_'

import { CompareSwiper } from './Compare.Portal.Swiper'
import { DiffSwitch }    from './Compare.Portal.Switch'

import { useScrollContainer } from 'react-indiana-drag-scroll'

export const ComparePortal = ({ close }) => {

  const [showDiff, setShowDiff] = useState(false)
  const isDesktop               = useBreak('md_up')
  const comps                   = useGet(comparesData).Units
  const scrollContainer         = useScrollContainer()

  const Count = () => <Col clr={clr}>{ comps.length }</Col>
  const Title = () => <span>Du har valgt <Count/> boliger til sammenligning</span>
  
  return (
    <>
      <Portal id={'CompareUnits'}>
        <Backdrop>
          <Container br={isDesktop} count={comps.length}>
            <Top>
              <Title>
                <Count/>
              </Title>
              <Icon icon='close2' size='12' color='black' wrap={{ position: 'absolute', right: 20 }} click={close} />
              <DiffSwitch showDiff={showDiff} setShowDiff={setShowDiff}/>
            </Top>
            <Main ref={scrollContainer.ref}>
              <CompareSwiper comps={comps} showDiff={showDiff} />
            </Main>
          </Container>
        </Backdrop>
      </Portal>
    </>
  )
}
