export const col = {
  mobile: [
    // { header:'#'      , key:1, data:'Index' },
    { header:'Status'  , key:1, data:'UnitStatusName'  , w:15 , align:0 },
    { header:'Etage'   , key:2, data:'Etage_Text'      , w:55 , align:1 },
    { header:'Dør'     , key:3, data:'Doer'            , w:45 , align:1 },
    { header:'Areal'   , key:4, data:'Areal'           , w:50 , align:2 },
    { header:'Pris'    , key:5, data:'Pris'            , w:100, align:2 },
    { header:'Vær.'    , key:8, data:'VaerelserAntal'  , w:40 , align:2 },
    { header:''        , key:6, data:''                , w:0  , align:0 },
  ],
  desktop: [
    { header:'Status'  , key:1, data:'UnitStatusName', w:15  , align:0 },
    { header:'Adresse' , key:2, data:'Adresse'       , w:220 , align:0 },
    { header:'Etage'   , key:3, data:'Etage_Text'    , w:55  , align:1 },
    { header:'Dør'     , key:4, data:'Doer'          , w:55  , align:1 },
    { header:'Areal'   , key:5, data:'Areal'         , w:50  , align:2 },
    { header:'Pris'    , key:6, data:'Pris'          , w:220 , align:2 },
    { header:'Værelser', key:8, data:'VaerelserAntal', w:50  , align:2 },
    { header:''        , key:7, data:''              , w:0   , align:0 },
  ]
}