import { getSessionID, getDeviceID, getSessionExpiry, setSessionID, setDeviceID, setSessionExpiry, isSessionExpired, toString } from 'logging/logging.functions.jsx'

import { nanoid }     from 'nanoid'
import { axios_live } from 'utilities/axios.instances'
import { LOG }        from 'api/api'
import { ip }         from 'state/store.global'
import { useGet }     from 'state/jotai'

window.log = function({ action  = '', unitId  = '', addData = {}, logData = {} }){
  
  const IP              = window.CIP
  const isSessionId     = getSessionID()
  const isDeviceId      = getDeviceID()
  const isSessionExpiry = getSessionExpiry()

  if ( !isDeviceId ) { const newlyGeneratedDeviceID = nanoid(); setDeviceID(newlyGeneratedDeviceID) }

  if ( !isSessionId ) { const newlyGeneratedSessionID = nanoid(); setSessionID(newlyGeneratedSessionID) }

  if ( !isSessionExpiry || isSessionExpired() ) {
    const newExpiry = new Date().getTime() + 2400000
    const newlyGeneratedSessionID = nanoid()
    setSessionExpiry(newExpiry)
    setSessionID(newlyGeneratedSessionID)
  }
  
  const data = {
    Action    : action,
    Data      : logData,
    DeviceID  : isDeviceId  ? isDeviceId  : getDeviceID(),
    SessionID : isSessionId ? isSessionId : getSessionID(),
    AddData   : { Referrer: document.referrer, IP:IP, ...addData },
    UnitID    : unitId,
  }
  axios_live.post(LOG(6), data)
    .then( res => {})
    .catch( err => console.log(err) )
}




export const useLog = ({}) => {

  const IP = useGet(ip)

  return ({action='', unitId='', addData={}, logData={}}) => {

    const isSessionId     = getSessionID()
    const isDeviceId      = getDeviceID()
    const isSessionExpiry = getSessionExpiry()
  
    if ( !isDeviceId ) { const newlyGeneratedDeviceID = nanoid(); setDeviceID(newlyGeneratedDeviceID) }
    if ( !isSessionId ) { const newlyGeneratedSessionID = nanoid(); setSessionID(newlyGeneratedSessionID) }
    if ( !isSessionExpiry || isSessionExpired() ) { const newExpiry = new Date().getTime() + 2400000; const newlyGeneratedSessionID = nanoid(); setSessionExpiry(newExpiry); setSessionID(newlyGeneratedSessionID); }
    
    const data = {
      Action    : action,
      Data      : logData,
      DeviceID  : isDeviceId  ? isDeviceId  : getDeviceID(),
      SessionID : isSessionId ? isSessionId : getSessionID(),
      AddData   : { Referrer: document.referrer, IP:IP, ...addData },
      UnitID    : unitId,
    }
    axios_live.post(LOG(6), data)
  }

}