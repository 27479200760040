import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  useMediaQuery,
} from "@mui/material";
import Unit from "components/Unit/Unit.model";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CustomInput, CustomLabel, CustomSelect } from "./styled-components";
import SuccessMessage from "./SuccessMessage";
import useHubspot from "./useHubspot";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";
import { useLocation } from "react-router-dom";

const ContactForm = ({ grid, unit, children }) => {
  const { submit, successMsg, errorMsg, inProgress, submitted } = useHubspot();
  const { register, handleSubmit, reset, formState: { errors, isValid }, } = useForm();
  const here = useLocation().pathname;
  const atUnit = here.includes("unit");
  const { pin, pid } = useGet(active_project) 

  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const isDesktop = useMediaQuery('(min-width:1200px)');

  const unitNo = unit?.getValue("Projeknr") ? `${buttonsLabel?.filter( btn => btn?.UILabelProperty === 'Bolignr')[0]?.Label} ${unit.getValue("Projeknr")},` : "";
  const unitAddress = unit?.getAddress() ?? "";

  const { i18n: { language }, } = useTranslation();
  const homeUrl = window.location.origin;
  const unitUrl = unit?.getUrl(`${homeUrl}/${language}`) ?? "";
  const SYS_B_ID = unit?.SYS_B_ID(`${homeUrl}/${language}`) ?? "";
  
  const [checked, setChecked] = React.useState(false);

  const handleCheckbox = (event) => { setChecked(event.target.checked); };


  useEffect(() => {
    if (submitted) {
      reset();
      setChecked(false);
    }
  }, [submitted]);

  const onSubmit = (data) => {
    const dataFields = [];
    for (let key in data) {
      if (key !== "_valid") {
        dataFields.push({ name: key, value: data[key] });
      }
    }
    submit(dataFields);
  };

  const noOfRoomsOption = ['1', '2', '3', '4']

  // select dropdown configs
  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;


  const MenuProps = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: !grid ? "right" : "left" // Don't pass grid from contact drawer as this condition won't work
    },
    transformOrigin: {
      vertical: "top",
      horizontal: !grid ? "right" : "left"
    },
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
        // width: dropdown_width
      },
    },
  };

  // handling the field which should have only number allowed
  const handleNumberInput = (event) => {
    return (event.key.charCodeAt() >= 48 && event.key.charCodeAt() <= 57) || event.key === 'Backspace' ? event.key : event.preventDefault();
  }

  return (
    <>
      {submitted ? (
        <SuccessMessage />
      ) : (
        <>
          <Box
            sx={{
              position: 'relative',
               color   : clr.sub_text_color
            }}
          >
            {children}
            {/* progress */}
            {inProgress && (
              <Box
                sx={{
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  left: 0,
                  top: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: 2,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 100,
                    height: 100,
                    backgroundColor: 'rgba(0,0,0,0.25)',
                  }}
                >
                  <CircularProgress />
                </Box>
              </Box>
            )}

            <form onSubmit={handleSubmit(onSubmit)}>
              {/* Have 2 values Skovlunde Bymidte and Nero*/}
               <input
                type='hidden'
                {...register('interested_in_project')}
                value={"Nero"}
              />
              <input
                type='hidden'
                {...register('appartment_finder_id_nero')}
                value={SYS_B_ID}
              />
              <Grid
                container
                columnSpacing={3}
                rowSpacing={grid ? 2 : 1}
                sx={{ mb: 3 }}
              >
                <Grid item md={grid ? 6 : 12} xs={ !grid ? 12 : 10}>
                  <FormControl
                    sx={{ mb: 3 }}
                    fullWidth
                    variant='filled'
                    error={errors.firstname ? true : false}
                  >
                    <CustomLabel shrink>
                      { buttonsLabel?.filter( btn => btn.UILabelProperty === 'Formularfornavn')[0]?.Label } &nbsp;<span>*</span>
                    </CustomLabel>
                    <CustomInput
                      {...register('firstname', { required: true })}
                    />
                  </FormControl>
                </Grid>

                <Grid item md={grid ? 6 : 12} xs={ !grid ? 12 : 10}>
                  <FormControl sx={{ mb: 3 }} fullWidth variant='filled'>
                    <CustomLabel shrink>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularEfternavn')[0]?.Label } &nbsp;<span>*</span></CustomLabel>
                    <CustomInput {...register('lastname', { required: true })} />
                  </FormControl>
                </Grid>

                <Grid item md={grid ? 6 : 12} xs={ !grid ? 12 : 10}>
                  <FormControl
                    sx={{ mb: 3 }}
                    fullWidth
                    variant='filled'
                    error={errors.email ? true : false}
                  >
                    <CustomLabel shrink>
                      { buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularEmail')[0]?.Label } &nbsp;<span>*</span>
                    </CustomLabel>
                    <CustomInput {...register('email', { required: true })} />
                  </FormControl>
                </Grid>

                <Grid item md={grid ? 6 : 12} xs={ !grid ? 12 : 10}>
                  <FormControl
                    sx={{ mb: 3 }}
                    fullWidth
                    variant='filled'
                    error={errors.phone ? true : false}
                  >
                    <CustomLabel shrink>
                     { buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularTelefon')[0]?.Label } &nbsp;<span>*</span>
                    </CustomLabel>
                    <CustomInput type="tel" onKeyDown={handleNumberInput} {...register('phone', { required: true })} />
                  </FormControl>
                </Grid>

                <Grid item md={grid ? 6 : 12} xs={ !grid ? 12 : 10}>
                  <FormControl
                    sx={{ mb: 3 }}
                    fullWidth
                    variant='filled'
                    error={errors.preferred_amount_of_rooms ? true : false}
                  >
                    <CustomLabel shrink>
                      Antal værelser
                    </CustomLabel>
                    <CustomSelect
                        displayEmpty
                        defaultValue={''}
                        input={<OutlinedInput {...register('preferred_amount_of_rooms', { required: true })} placeholder="" />}
                        renderValue={(selected) => {
                          if (!selected?.length) {
                            return <CustomLabel shrink>
                              Vælg antal værelser
                           </CustomLabel>
                          }
                          return selected;
                        }}
                        MenuProps={MenuProps}
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem value="">
                          <CustomLabel shrink>
                            Vælg antal værelser
                          </CustomLabel>
                        </MenuItem>
                        {noOfRoomsOption?.map((room) => (
                          <MenuItem
                            key={room}
                            value={room}
                          >
                            {room}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                  </FormControl>
                </Grid>
                {/* <Grid item md={grid ? 6 : 12} xs={12}>
                  <FormControl sx={{ mb: 3 }} fullWidth variant='filled'>
                    <CustomLabel shrink>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularAdresse')[0]?.Label }</CustomLabel>
                    <CustomInput {...register('address')} />
                  </FormControl>
                </Grid> */}

                {/* <Grid item md={grid ? 6 : 12} xs={12}>
                  <FormControl sx={{ mb: 3 }} fullWidth variant='filled'>
                    <CustomLabel shrink>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularPostnummer')[0]?.Label }</CustomLabel>
                    <CustomInput {...register('zip')} />
                  </FormControl>
                </Grid> */}

                {/* <Grid item md={grid ? 6 : 12} xs={12}>
                  <FormControl sx={{ mb: 3 }} fullWidth variant='filled'>
                    <CustomLabel shrink>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'PersonalIdentityNumber')[0]?.Label }</CustomLabel>
                    <CustomInput type="number" {...register('personal_identity_number')} />
                  </FormControl>
                </Grid> */}

                {/* <Grid item md={12} xs={12}>
                  <FormControl sx={{ mb: 3 }} fullWidth variant='filled'>
                    <CustomLabel shrink>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularKommentar')[0]?.Label }</CustomLabel>
                    <CustomInput
                      multiline
                      rows={4}
                      {...register('kommentar')}
                    />
                  </FormControl>
                </Grid> */}
              </Grid>

              <Box sx={{ mb: 3 }}>
                <CustomLabel>
                  { buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularBetingelser')[0]?.Label } &nbsp;<span>*</span>
                </CustomLabel>
                <FormControlLabel
                  sx={{
                    mr: 0,
                    "span":{    color: clr?.alternative_condition },
                  
                    "a":{color: `${clr?.alternative_condition} !important` },
                  }}
                 
                  control={
                    <Checkbox
                      name='_value'
                      checked={checked}
                      onChange={handleCheckbox}s
                    />
                  }
                  label={
                    <Box >
                      <div  dangerouslySetInnerHTML={{ __html: buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularBetingelserTekst')[0]?.Label }}>
                      </div>
                    </Box>
                  }
                />
              </Box>

              <Box
                sx={{
                  textAlign: grid ? 'left' : 'center',
                }}
              >
                <Box>
                <Button
                type='submit'
                disabled={!checked || !isValid}
                variant = {"contained"}
                size    = "large"
                sx      = {{
                  textAlign: grid ? 'left' : 'center',
                  borderRadius: '6px',
                  color       : clr?.buttons_text,
                  border      : 'none',
                  padding     : "11px 24px 11px 24px",
                }}          
              >
                <span className = "paragraph1-bold" style = {{fontWeight : 600 }}>
                  {/* { buttonsLabel?.filter( btn => btn.UILabelProperty === 'signUp')[0]?.Label } */}
                  Tilmeld
                </span>
              </Button>
                </Box>
                <Box
                  sx={{
                    my: 3,
                    color: 'success.main',
                  }}
                >
                  <Box sx={{ color: 'success.main' }}>{successMsg}</Box>
                  <Box sx={{ color: 'error.main' }}>{errorMsg}</Box>
                </Box>
              </Box>
              {/* <div style={{height:50}}/> */}
            </form>
          </Box>
        </>
      )}
    </>
  )
};

ContactForm.propTypes = {
  children: PropTypes.node,
  grid: PropTypes.bool,
  unit: PropTypes.instanceOf(Unit),
};

export default ContactForm;
