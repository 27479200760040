import styled from 'styled-components/macro'
import { MENU, BREAK_ } from 'constants'

export const Menu = styled.div`
  position:fixed;
  z-index:10;
  top:0;
  left:0;
  height: 74px;
  width:100%;
  background-color: ${ ({clr}) => clr.primary };
  color: ${ ({clr}) => clr.primary_text };
  display:flex;
  > span:nth-child(1) {
    /* border:1px solid blue; */
    display:flex;
    align-items:center;
    justify-content:flex-start;
    align-items:center;
    max-width:120px;
  };
  > span:nth-of-type(2) {
    /* border:1px solid green; */
    /* flex:1; */
    display:flex;
    justify-content:flex-end;
  }

`

export const Gap = styled.div`
width:${({i}) => i}px;
`
export const Section = styled.div`
  display: flex;
  background-color: ${({ clr }) => `${clr?.buttons_text}bf`};
  color: ${({ clr, pin, showButton }) => ('#000')};

  height: 44px;
  border-radius: 8px;
  border: 1px solid ${({ clr, pin, showButton }) => ( `${clr?.buttons_text}`)};
  justify-content: center;
  align-items: center;
   padding: 0 10px;
  gap: 10px;
  line-height: 1;
  cursor: pointer;
  user-select: none;
  width: 185px;
`
export const Text = styled.div`
  white-space: nowrap;
  font-size: 18px;
  font-weight: 400px;
  color: ${({ clr, atUnit }) => atUnit ? `${clr?.primary_text_passive}` : `${clr?.primary_text_passive}`};
`;
export const BackBox = styled.div`
  gap: 5px;
  cursor: pointer;
  padding: 5px 15px;
  z-index: 5;
  align-items: center;
  display: flex;
  gap: 5px;
  user-select: none;
  ${BREAK_.md_dn} {
    position: fixed;
    top: 90px;
    left: 16px;
    padding: 0;
  }
`;

export const CarratLeft = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  bottom: -19px;
  background: ${({bgColor}) => bgColor ? bgColor : '#EFEFEF' };
  clip-path: polygon(36% 0, 26% 4%, 15% 11%, 6% 22%, 0 32%, 0 0);
  z-index: 5;
`

export const CarratRight = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  right: 0;
  bottom: -19px;
  transform: rotateY(180deg);
  background: ${({bgColor}) => bgColor ? bgColor : '#EFEFEF' };
  clip-path: polygon(36% 0, 26% 4%, 15% 11%, 6% 22%, 0 32%, 0 0);
   z-index: 5;
`
