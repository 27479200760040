import { Box } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

import { Icon } from "components/Icons";


function IconBadge({ badge, ...props }) {
  // console.log("🚀 ~ IconBadge ~ props:", props)
  return (
    <Box component="span" sx={{ position: "relative", display: "inline-flex" }}>
      <Icon {...props}  isFaIcon={true}/>
      {badge && (
        <Box
          component="span"
          sx={{
            position: "absolute",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: 14,
            height: 14,
            lineHeight: 1,
            fontSize: 10,
            fontWeight: 700,
            backgroundColor: "#7b2c1c",
            color: "#fff",
            top: props.icon ==="heart_empty" ? -3 : -6,
            right:props.icon ==="heart_empty" ? -7 : -9,
            borderRadius: "50%",
          }}
        >
          {badge}
        </Box>
      )}
    </Box>
  );
}

IconBadge.propTypes = {
  badge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  
};

export default IconBadge;
