import BeatLoader from "react-spinners/BeatLoader";
import { B1, B2, Bar, Progress, Percent } from './Loading2.style'

export const Loading2 = ({ progress, show }) => ( show ) 
  ? progress
  ? (
    <>
      <B1>
        <ProgressBar pct={progress}/>
      </B1>
    </>
  ) : ( 
    <B2>
      <BeatLoader color={clr?.primary} size={20}/>
    </B2>
  ) : null

  
const ProgressBar = ({ pct }) => (
  <Bar>
    <Progress pct={ pct }/>
    <Percent>{`${ pct }${ pct ? '%' : ''}`}</Percent>
  </Bar>
)