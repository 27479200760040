
import { useBreak } from "hooks/useBreak"
import { Label } from "./Overview.label"
import { _bootstrap } from "state/store.bootstrap"
import { getFocus, useAtom, useGet } from "state/jotai"
import { current_language, rotate_by_slider } from "state/store.global"
import { AnimatePresence } from "framer-motion"
import { motion } from 'framer-motion'
import { MobileLabel } from "./MobileOverview.label"
import { _project } from "state/store.projects"

export const Overview = ({ index, rmode, status, showLabel, setShowLabel ,hoveredId}) => {

    const labelOptions    = getFocus(_bootstrap, 'projectnames')
    // console.log(labelOptions)
    const currentLanguage = useAtom(current_language)[0];
    const isRotate_Done = useGet(rotate_by_slider)
    const isDesktop = useBreak('md_up')
    
    const L = [ // desktop labels position
    { L1:[1150, 260] , L2:[653, 400] },
    { L1:[1200, 290] , L2:[770, 150] },
    { L1:[750, 290]  , L2:[1450, 230] },
    { L1:[650, 240]  , L2:[1000, 450] },
    { L1:[1153, 260] , L2:[653, 400] },
    ];

    const circle = [        // desktop circle position
        { L1:[50, 300] , L2:[50, 300] },
        { L1:[40, 300] , L2:[40, 300] },
        { L1:[40, 300] , L2:[40, 300] },
        { L1:[40, 300] , L2:[40, 300] },
        { L1:[50, 300] , L2:[50, 300] },
    ]

    const mobileL = [   // circle position 
        { L1:[1120, 200] , L2: [800 , 400]   },
        { L1:[1200, 255] , L2: [800 , 100]   },
        { L1:[890, 400]  , L2: [1400 , 100]   },
        { L1:[650, 155]  , L2: [1100 , 500]   },
        { L1:[1120, 210] , L2: [800 , 400]   },
        ];

    const mobileLine = [    // how much line shoule be big from both axis
        { L1:[ 450, 0 ]},
        { L1:[ 400, 0 ]},
        { L1:[ 550, 0 ]},
        { L1:[ 500, 0 ]},
        { L1:[ 450, 0 ]},
        ];

    const mobileLineAngle = [       // labels line rotation from the pin point (circle)
        { L1: 215},
        { L1: 285},
        { L1: 85},
        { L1: -285},
        { L1: 215},
        ];

    const mobileLabelAxis = [       // labels position (i.e top & left)
        { L1:[ 300, 0 ] , L2:[300 , 0] },
        { L1:[ 300, 0 ] , L2:[300 , 0] },
        { L1:[ 300, 0 ] , L2:[300 , 0] },
        { L1:[ 300, 0 ] , L2:[300 , 0] },
        { L1:[ 300, 0 ] , L2:[300 , 0] },
        ];

    const animation = {
        initial: { opacity:0 },
        animate: { opacity:1 },
        exit:    { opacity:0 },
        transition: { duration:0.1, ease:'linear' },
      }

    return (
        <AnimatePresence>
        {
            status === 'DONE' && isRotate_Done &&
            <>
            {
                isDesktop ? 
                labelOptions?.[currentLanguage].map((label, i) => {
                  
                    if(i) { // labels will generate according to different properties
                        return (
                                <motion.div {...animation} key={i}>
                                     <Label setShowLabel={setShowLabel}  c={L?.[index]?.[`L${i}`]} sc={1} d={40} l={0} r={circle?.[index]?.[`L${i}`]} {...{ rmode }} labelsInfo = {label} showLabel={showLabel} hoveredId={hoveredId}/> 
                                </motion.div>)
                    }
                })
                :
                labelOptions?.[currentLanguage].map((label, i) => {
                    if(i) { // labels will generate according to different properties
                        return (
                                <motion.div {...animation} key={i}>
                                    <MobileLabel setShowLabel={setShowLabel} c={mobileL?.[index]?.[`L${i}`]} sc={1} d={40} l={0} r={circle?.[index]?.[`L${i}`]} {...{ rmode }} labelsInfo = {label} line={mobileLine?.[index]?.[`L${i}`]} angle={mobileLineAngle?.[index]?.[`L${i}`]} mobileLabelAxis={mobileLabelAxis?.[index]?.[`L${i}`]} showLabel={showLabel} hoveredId={hoveredId}/>
                                </motion.div>)
                    }
                })
            }
            </>  
        }
        </AnimatePresence>
    )
}