import { _project } from 'state/store.projects'
import { active_project }   from 'state/store.global'
import { getFocus, useGet } from 'state/jotai'

export const useGetUnit = (UnitID) => {

  const { pin, pid } = useGet(active_project)
  const Units = getFocus(_project, 'units')?.[pin]?.[pid]?.Units ?? []
  const Unit = Units?.find( i => i.UnitID == UnitID ) ?? []

  return Unit

}