const isSupported = () => {
  try {
    localStorage.setItem('supported', '1')
    localStorage.removeItem('supported')
    return true
  } 
  catch (error) { 
    return false 
  }
}

export const ls = {
  get : (key)        => { if (isSupported()) return localStorage.getItem(key) },
  set : (key, value) => { if (isSupported()) localStorage.setItem(key, value) },
  rem : (key)        => { if (isSupported()) localStorage.removeItem(key) }
}