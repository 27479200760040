import { forwardRef } from 'react'
import { TransformWrapper } from '@pronestor/react-zoom-pan-pinch'
import { TransformComponent } from '@pronestor/react-zoom-pan-pinch'

export const ZoomPanPinch = forwardRef(
  ( { children, wrapperStyle, contentStyle, wrapperProps }, ref ) => {

    return (
      <TransformWrapper
        {...wrapperProps}
        ref={ref}
      > 
        <TransformComponent
          wrapperStyle={wrapperStyle}
          contentStyle={contentStyle}
        >
          { children }
        </TransformComponent>
      </TransformWrapper>
    )
  }
)