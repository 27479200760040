import { useEffect, useState } from 'react'
import { useSWRConfig }        from 'swr'
import { useFetch }            from 'hooks/fetch/useFetch'
import { usePrevious }         from 'hooks/usePrevious'
import { _project }            from 'state/store.projects'
import { setFocus, getFocus }  from 'state/jotai'
import { fn3 }                 from 'utilities/utility.misc'
import { applyfilters }        from 'utilities/utility.filter'
import { applysorting }        from 'utilities/utility.sort'
import { useTranslation }      from 'react-i18next'
import { UNITS, VERSION }      from 'api/api'

export const useFetch_Units = ({ pid=null, pin=null } = {}) => {

  const { i18n:{language} } = useTranslation()

  const [ version, setVersion ] = useState(0)
  const [ init, setInit ] = useState(false)

  const filters       = getFocus(_project, 'activefilters')?.[pin]?.[pid]

  const sorting       = getFocus(_project, 'activesorting')?.[pin]?.[pid]

  const setUnits      = setFocus(_project, 'units')
  const setRaw        = setFocus(_project, 'unitsraw')
  const setCategories = setFocus(_project, 'unitcategories')
  
  const done        = d => setVersion(d.Dataversion)
  const update      = useSWRConfig().mutate
  const prevVersion = usePrevious(version)

  const file = 'useFetch_Units.jsx'
  const sec  = 10

  const url1 = pid ? VERSION(pid) : null
  const url2 = pid ? UNITS(pid,language) : null

  const {     } = useFetch({ url:url1, file, sec, done })
  const {D,L,E} = useFetch({ url:url2, file })

  useEffect(()=>{

    if ( prevVersion !== version && init !== false ) update( url2 )
    if ( D && pin >= 0 ) {
      setInit(true)
      const { Units, ...rest } = D
      const units_f = applyfilters( Units, [] )
      
      const units_s = applysorting( units_f, sorting )
      const units   = { ...rest, Units:units_s }

      const raws_s = applysorting( Units, sorting )
      const raws   = { ...rest, Units:raws_s }

      const cats = Units.map(i=>i.Data.Boligtype.Value)
      const cats_ = ['Alle boligtyper',...cats]
      const unitcategories = [...new Set(cats_)]

      // clog( 'unitcategories', unitcategories )

      setCategories  (a => fn3(a,pin,pid,unitcategories) )
      setUnits       (a => fn3(a,pin,pid,units) )
      setRaw         (a => fn3(a,pin,pid,raws) )
      
    } 

  },[version,D,pin,pid, sorting])

  return {D,L,E}

}