import styled from 'styled-components/macro'

export const NoMatch = () => (
  <NoMa>Siden du søgte eksisterer ikke</NoMa>
)

const NoMa = styled.div`
  width:100%;
  height:100%;
  display:flex;
  justify-content:center;
  align-items:center;
`