import { IP } from 'api/api'
import { axios_live } from 'utilities/axios.instances'

( async () => await axios_live
    .get( IP )
    .then( res => window.CIP = res.data.IP )
    .then( res => { console.log( 'Your Public IP: ', window.CIP ); return }
    )
)()
.catch( err => {

  console.log( err )
  return
})