import { useEffect }      from 'react'
import { useSwiperSlide } from 'swiper/react'
import { useSetAtom }  from 'state/jotai'
import { atom }           from 'jotai'

export const a_slide = atom({})

export const CatchSlide = () => {

  const slide = useSwiperSlide()
  
  const setSlideAtom = useSetAtom(a_slide)

  useEffect(()=>{
    if ( slide ) {
      setSlideAtom(slide)
    }
  },[slide])

  return null

}