import { ls } from 'utilities/localstorage/localstorage.check'
import { nanoid } from 'nanoid'
import { SESSION_ID_KEY, DEVICE_ID_KEY, SESSION_EXPIRY_KEY } from 'constants'

export const getSessionID     = (      ) => ls.get( SESSION_ID_KEY ) ?? ''
export const getSessionExpiry = (      ) => ls.get( SESSION_EXPIRY_KEY ) ?? ''
export const setSessionExpiry = (expiry) => ls.set( SESSION_EXPIRY_KEY , expiry )
export const setSessionID     = (      ) => ls.set( SESSION_ID_KEY, nanoid() )
export const getDeviceID      = (      ) => ls.get( DEVICE_ID_KEY ) ?? ''
export const setDeviceID      = (      ) => ls.set( DEVICE_ID_KEY, nanoid() )
export const isSessionExpired = (      ) => {
  
  const now       = new Date().getTime()
  const expiry    = getSessionExpiry()
  const isExpired = Number(expiry - now) < 0
  
  return isExpired
}

export const toString = (d) => JSON.stringify(JSON.parse(d),0,4)