import styled from 'styled-components/macro'
import { sb } from 'styles/styles.scrollbar'
import { BREAK_ } from 'constants'

export const Pad = styled.div`
  /* padding: 3% 3% 3% 3%; */
  padding:5px;
  background: #f7f7f7;
  width:${({count}) => count * 380/count}px;
  :first-child { border-left:1px solid gray };
  :last-child { border-right:1px solid gray };
  ${ BREAK_.md_up } { width:100%; }
`
export const Row = styled.div`
  ${ BREAK_.md_up } { width:100%; }
  display: flex;
  justify-content:center;
  gap:10px;
  width: fit-content;
  /* border:10px solid orange; */
`
export const GroupHead = styled.div`
  height: 60px;
  font-weight: 600;
  position: sticky;
  left: 0;
  padding-left: 20px;
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  background: #fff;
  border:1px solid gray;
  /* width:300px; */
`
export const Wrap = styled.div`
  overscroll-behavior:none;
  overflow-x:scroll;
  overflow-y:scroll;
  cursor:grab;
  height:100%;
  position:relative;
  ${ sb.hide };
`