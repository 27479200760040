import styled from 'styled-components/macro'
import { useState } from 'react'
import Spinner from 'react-spinners/BeatLoader'
import { BREAK_ } from 'constants'
import { ImgWrapper } from 'components/ImgContainer/ImgWrapper'

export const Thumbnail = ({ thumbnail }) => {
  const [loading, setLoading] = useState(true)
  const imageLoaded = () => setLoading(false)
  return (
    <>
      <Section>
        {/* <img
          style={{ display: loading ? 'none' : 'unset' }}
          src={thumbnail}
          alt=''
          onLoad={imageLoaded}
        />
        {loading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner color={'#fff'} size={20} />
          </div>
        )} */}
          <ImgWrapper img={thumbnail}/>
      </Section>
      {/* <div style={{minWidth:10}}></div> */}
    </>
  )
}

export const Section = styled.div`
  display: flex;
  padding: 1px 0;
  flex-direction: column;
  line-height: 0.8125rem;
  flex:0 0 280px;
  height: 168px;
  justify-content:center;
  ${ BREAK_.md_dn } {
    flex: 0 0 200px;
  }
  /* margin-right:10px; */
  img {
    height: 100%;
  }
`