import styled from 'styled-components/macro'
import { BREAK_ } from 'constants'
import { motion } from 'framer-motion'

export const Main = styled(motion.div)`
  background-color: ${({ clr }) => `${clr.primary}e6`};
  border-radius:8px;
  position: fixed;
  bottom: 82px;
  left: ${({ isDesktop }) => (isDesktop ? '48px' : '15px')};
  width: ${({ isDesktop }) => (isDesktop ? '520px' : '345px')} ;
  z-index: 1;

  
`
export const Option = styled.div`
  display: flex;
  cursor: pointer;
  // width: 245px;
  font-size: 1.0625rem;
  border-bottom: 1px solid #fff;
  // background: ${({ active }) => (active ? '#E6E6E6' : 'transparent')};
  justify-content: center;
`
export const Cell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px;
  flex: 1;
`
export const Text = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  width: 100%;
  color: ${({ clr }) => clr.primary_text};
  justify-content: start;
  padding-bottom: 10px;
`
export const SubText = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  width: 100%;
  color: #233B33;
  justify-content: start;
  line-height: 1.2;
  color: ${({clr}) => clr?.primary_text};

`
export const Values = styled.div`
padding-bottom:5px;
white-space: pre-line;
`
