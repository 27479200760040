import styled   from 'styled-components/macro'
import { data } from 'components/Favorites/Card/Card.data'
import { form } from 'components/Favorites/Card/Card.data'
import { BREAK_ } from 'constants'

export const Text = ({atUnit, unit, cardType, mode}) => {
  const type = 'A'
  return (
    <UnitInfo atUnit={atUnit} className='h4' cardType={cardType} >
      {
        // paragraph1-light h4
        data[type].map( line => {
          const d = form(unit,line);
          if (d === "" ) return null;
          return <Line mode={mode} className= {`${line?.key?.toString() === "1" ? "h4" : "paragraph1-light"}`} key={line.key}>{ d }</Line>
        })
      }
    </UnitInfo>
  )
}

const Line = styled.div`
  // white-space: nowrap;
  // text-overflow: ellipsis;
  overflow: ${({mode}) => mode === 'card' ? "visible" : "hidden" };
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  :nth-child(1) { font-weight: 600; }
  /* border: 1px solid black; */
  /* outline: 1px solid blue; */
`

export const UnitInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: hidden;
  min-height: 134px;
  padding: ${({ cardType }) => "8px 24px" };

  ${BREAK_.md_dn} {
    min-height: ${({cardType, atUnit}) => cardType === "grid" ? atUnit ? "130px" : "143px" : "134px"};
    padding: ${({ cardType }) => cardType === "grid" ? "16px" : "8px 24px" };
  }
`