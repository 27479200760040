import { css } from 'styled-components'
import { BREAK_ } from 'constants'

export const root = css`
  /* Create a root stacking context */

  #root {
    height: auto;
    overflow: auto;

    ${BREAK_.md_up} {
      height:100%;
    }
  }
  /* For WebKit-based browsers */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent;
    display: none;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #909090;
    border-radius: 20px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
  
  /* For Firefox */
  scrollbar-color: lightgray white;
  scrollbar-width: thin;

  ::-webkit-scrollbar-track {
    background-clip: padding-box;
    background: #dddddd;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }


  .trackY {
    background: transparent !important;
    right: 4px !important;

    
   ${BREAK_.md_dn} {
      height:100%;
      right: 0px !important;
    }
  }

  .ScrollbarsCustom-Wrapper {
    inset: 0px 0px 0px 0px !important;
  }
  
  .ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
    width: 5px !important;
    margin-left: auto;
    position: relative;
    z-index: 5;
    background: #a9a9a9b8 !important;
  }

  * {scroll-behavior: smooth;}

  /* For Chrome and Safari */
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset; /* Override the background color */
    -webkit-text-fill-color: black; /* Override the text color if needed */
  }

  /* For Firefox */
  input:-moz-autofill {
    background-color: white !important; /* Override the background color */
    color: black !important; /* Override the text color if needed */
  }
    
  * {
  scroll-behavior: smooth; 
  box-sizing: border-box !important;
  }

  .swiper-scrollbar-horizontal .swiper-scrollbar-drag {
    background: #A9A9A9b8 !important;
  }

  @media only screen and (max-width: 600px) {
    select, input, textarea, react-select__value-container {
      font-size: 16px!important;
    }
  }
`