import { Stack } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { ButtonCompare, ButtonFavorite, ButtonShare } from ".";
import { useLocation } from "react-router-dom";
import { useBreak } from "hooks/useBreak";
function Buttons({ unitId, address, highlights, rent }) {
  const here = useLocation().pathname;
  const atUnit = here.includes("unit");
  const isMobile = useBreak("md_dn");

  return (
    <Stack
    spacing={0}
      direction="row"
      sx={ {  height:"40px" , bgcolor: isMobile ? `#EFEFEF`: atUnit ? '#FFFFFFBF' : `#EFEFEF`, padding: { md: atUnit ? "8px 16px" : "8px 41px", xs: atUnit ? "8px 41px" : "8px 41px" }, borderRadius: atUnit ? '6px' : '0', justifyContent: 'space-around', alignItems: 'center', }}
    >
      {/* <div style={{width:"300px",display:"flex" , justifyContent:"space-evenly"}}> */}
     <div  className="icon-hover-effect "> <ButtonShare unitId={unitId} address={address} rent={rent} highlights={highlights} /></div>
     <div style={{padding : isMobile ? "0 28px" : "0 48px"}} className="icon-hover-effect "><ButtonCompare unitId={unitId} /></div> 
     <div   className="icon-hover-effect "><ButtonFavorite unitId={unitId} /></div> 
     {/* </div> */}
    </Stack>
  );
}

Buttons.propTypes = {
  unitId: PropTypes.number.isRequired,
};

export default Buttons;
