import styled       from 'styled-components/macro'
import { useState } from 'react'
import { Slider }   from './Filter.Controls.Interval.Slider'
import { useAfter } from './Filter.Controls.Interval.after'

export const Interval = ({active,setFilters,Default,FilterFor,MaxValue,MinValue,PossibleValues,Postfix,Steps}) => {

  const state = active?.interval?.find(i=>i.property == FilterFor ) ?? null
  const initial = state ? [state.min, state.max] : [ MinValue, MaxValue ]
  const [value, setValue] = useState(initial)
  const change = e => setValue(e)
  const after = useAfter({ active, setFilters, Default, FilterFor })

  // clog( FilterFor, PossibleValues, Default, MaxValue, Postfix, Steps )
  // clog( 'possiblevalues', FilterFor, PossibleValues, value[0], value[1], Default[0], Default[1] )

  return (
    <>
      <Box>
        <Slider
          dv     = {[MinValue, MaxValue]}
          pf     = {Postfix}
          pv     = {PossibleValues}
          value  = {value}
          step   = {Steps}
          max    = {MaxValue}
          min    = {MinValue}
          change = {change}
          after  = {after}
          name   = {FilterFor}
        />
      </Box>
    </>
  )
}

const Box = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`