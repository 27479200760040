import { _project } from './store.projects'
import { getFocus, setFocus } from 'state/jotai'
import { activeProject } from 'state/jotai'
import { fn3 } from 'utilities/utility.misc'

const pr = key => getFocus(_project, key)
const pr_ = key => setFocus(_project, key)

export const setProjectData = key => {
  const { pin, pid } = activeProject()
  const set = pr_(key)
  return update => 
    set(a => fn3(a, pin, pid, update))
}

export const getProjectData = (key, sub = false, alt = false) => {
  const { pin, pid } = activeProject()
  return sub
    ? pr(key)?.[pin]?.[pid]?.[sub] ?? alt
    : pr(key)?.[pin]?.[pid] ?? alt
}
