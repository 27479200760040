import { useAtom } from "state/jotai";
import { favorites as stateFavorites } from "state/store.global";

const useFavorites = () => {
  const [items, setItems] = useAtom(stateFavorites);
  const count = items.length;

  const toggle = (unitId) => {
    if (isIncluded) {
      remove(unitId);
    } else {
      add(unitId);
    }
    setItems([...items, unitId]);
  };

  const add = (unitId) => {
    setItems([...items, unitId]);
  };

  const remove = (unitId) => {
    setItems(items.filter((id) => id !== unitId));
  };

  const isIncluded = (unitId) => items.includes(unitId);

  return {
    toggle,
    add,
    remove,
    isIncluded,
    count,
  };
};

export default useFavorites;
