import styled, { css } from 'styled-components/macro'
import { lineTo } from './Map.utilities'

export const Label = styled.div(
({ x, y, origin, hover, disabled }) => css`
  display:flex;
  flex-direction:column;
  justify-content:center;
  gap:2px;
  position: absolute;
  color: #000;
  transform: ${ lineTo(origin) };
  left: ${x}px;
  top: ${y}px;
  cursor: pointer;
  transition: background-color 0.5s;
  background-color: ${(()=>{
    if ( !disabled ) return hover ? '#ffffffff' : '#ffffff80' 
    if ( disabled )  return hover ? '#00000080' : '#00000080'
  })()};
  :hover {
    background-color: ${ !disabled ? '#ffffffff' : '#00000080' };
    cursor: ${ !disabled ? 'pointer' : 'unset' }
  }
`)
export const Name = styled.div`
  white-space:nowrap;
  font-size:${ ({s}) => 15 * s }px;
  font-weight: 600;
  letter-spacing:0.25px;
  color:'#000';
  pointer-events:none;
  text-transform: uppercase;
  text-align:center;
  display:flex;
  flex-direction:column;
  justify-content:center;
  line-height:1;
`
export const Sub = styled.div`
  white-space:nowrap;
  font-size:${ ({s}) => 10 * s }px;
  font-weight: 400;
  letter-spacing:0.5px;
  color:'#000';
  pointer-events:none;
  text-align:center;
  display:flex;
  flex-direction:column;
  justify-content:center;
  line-height:1;
`