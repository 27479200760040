import styled                   from 'styled-components/macro'
import { Swiper }               from 'swiper/react'
import { Pagination, Keyboard } from 'swiper'
import { FreeMode }             from 'swiper'
import { css }                  from 'styled-components'

import 'swiper/css';
import 'swiper/css/pagination';

export const SliderStyle = css`.swiper-slide {
  ${'' /* user-select:none; */}
  display: flex;
  justify-content:center;
  ${'' /* height: auto; */}
  ${'' /* width: 100%; */}
  border:1px solid orange;
}`

export const SwiperStyle = css`.swiper {
  ${'' /* display: flex; */}
  ${'' /* width: 100%; */}
  ${'' /* height: 100%; */}
  ${'' /* justify-content:center;  */}
  ${'' /* align-items:flex-end; */}
  border:1px solid purple;
}`

const StyleInjection = styled.div`
  /* ---------------------------------- */
  /* height:100%;  */
  /* ---------------------------------- */
  border:1px solid blue;
  width:100%;
  ${ SwiperStyle }
  ${ SliderStyle }
`

export const HozSwipe = ({ children }) => {
  
  const swiper = {
    modules: [Pagination, Keyboard],
    slidesPerView: 1.5,
    spaceBetween: 20,
    // initialSlide: 0,
    pagination: { dynamicBullets: true },
    // keyboard: { enabled: true },
    // onSlideChange : e => { setActiveIndex( e.activeIndex ) }
  }
  
  return (
    <>
      <StyleInjection>
        <Swiper {...swiper}>
          { children }
        </Swiper>
      </StyleInjection>
    </>
  )
}


// const [activeIndex, setActiveIndex] = useState(null)
