import styled  from 'styled-components/macro'
import { BREAK_ } from 'constants'

export const Content = styled.div`
  display: flex;
  padding: 0px 37px;
  flex-direction: column;
  width: 100%;
  isolation:isolate;
  z-index:0;
  min-height: calc(100vh - 200px);
  justify-content: space-between;

  height: ${({ units, isDesktop }) => !units ? `calc(100vh - ${isDesktop ? '150px' : '200px'})` : '100%'};
  ${({ units }) => !units ? 'justify-content: space-between' : ''};
  /* border:10px solid red; */
  ${ BREAK_.md_dn } { 
    padding:0 16px;
  }
`
export const Bottom = styled.div`
  // height: 90px;
  display: flex;
  justify-content: center;
  padding-top: 15px;
`
export const Sticky = styled.div`
  display: flex;
  /* background-color: #fff; */
  flex-direction: column;
  /* justify-content:stretch; */
  /* align-items:stretch; */
  position: sticky;
  top: ${({ top }) => top}px;
  z-index: ${({ z }) => z};
  /* border:6px solid orange; */
`
export const Space = styled.div`
  width: ${({ h }) => (h ? h : 0)}px;
  height: ${({ v }) => (v ? v : 0)}px;
`
export const Padding = styled.div`
  /* border:10px solid orange; */
  overflow-x:hidden;
  overflow-y:scroll;
  height:100%;
`