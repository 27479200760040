
import { useState } from 'react'
import { Backdrop } from 'styles/styles.backdrop'
import { getFocus, useAtom, useGet } from 'state/jotai'
import { Button, Stack } from '@mui/material'
import { STATICS } from 'api/api'
import { Icon } from 'assets/Icons_'
import { active_project, vis_calculate_benefit } from 'state/store.global'
import { housing_benefit } from 'state/store.global'
import { gross_income } from 'state/store.global'
import { Container, Inner, Result, SubText, CurrencyInput } from './HousingBenefit.style'
import './HousingBenefit.css'
import { useHousingBenefit } from './HousingBenefit.hook'
import { _project } from 'state/store.projects'

const data = {
  Header1: 'Beregn din boligstøtte',
  Text1  : 'Indtast din husstands årlige bruttoindtægt (månedsløn før skat x 12), og få lavet en vejledende beregning for din månedlige boligstøtte',
  Header2: 'Husstandens årlige bruttoindtægt',
  Header3: 'Din vejledende boligstøtte',
  SubText: 'Den boligstøtte beregning du kan se her, er vejledende og vi giver ingen garanti i forhold til den endelige boligstøtte du måtte kunne få. Brug eventuelt https://www.boligstoette.dk/ som har en beregner der stilles til rådighed af Udbetaling Danmark.',
}

export const BenefitPortal = () => {
  const [show, setShow]      = useAtom(vis_calculate_benefit)
  const [benefit,setBenefit] = useAtom(housing_benefit)
  const [income,setIncome]   = useAtom(gross_income)
  const [val, setVal]        = useState('')
  
  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []
  
  const change = e => {
    setIncome({
      UnformattedValue:e.target.value.replace(/,|\.| kr\./g,''),
      Value:e.target.value
     } )
  }

  const output = useHousingBenefit()

  const icon = {
    icon: 'close6',
    color: '#fff',
    wrap: { position: 'absolute', top: 20, right: 20 },
    size: '20',
    click: () => setShow(false),
  }

  return (
    <>
      <Backdrop
        onClick={() => {
          setShow(false)
        }}
      />
      <Container>
        <img src={`${STATICS}generic.png`} alt='' />
        <Icon {...icon} />
        <Inner>
          <h2>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'BoligstøtteOverskrift')[0]?.Label }</h2>
          <p>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'BoligstøtteUnderoverskrift')[0]?.Label }</p>
          <div style={{ height: 40 }} />
          <h2>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'BoligstøtteLommeregnerensOverskrift')[0]?.Label }</h2>
          {/* <Input type='text' onChange={change}></Input> */}
          <CurrencyInput
            onChange={change}
            placeholder={income.Value}
          />
          <div style={{ height: 30 }} />
          <h2>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'BoligstøtteBeregnetOverskrift')[0]?.Label }</h2>
          <Result>{output}</Result>
          
          <SubText>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'BoligstøtteVejledendeTekst')[0]?.Label }</SubText>
          <Stack direction='row' spacing='1px' justifyContent='center'>
            <Button variant='contained' size='large' onClick={() => setShow(false)}>
            { buttonsLabel?.filter( btn => btn.UILabelProperty === 'BoligstøtteGemknap')[0]?.Label }
            </Button>
          </Stack>
        </Inner>
      </Container>
    </>
  )
}
