import styled from 'styled-components/macro'

export const Container = styled.div`
  max-width: 100vw;
  // width: 700px;
  width :  ${props => (props.isMobile ? '350px' : '700px')};
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  user-select: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;



`


export const Inner = styled.div`
  padding: 24px 24px 24px 24px;
  h2 {
    font-size: 18px;
    margin: 10;
  }
  h3 {
    font-size: 16px;
    margin: 10;
  }
  p {
    font-size: 17px;
    line-height: 24px;
  }
`

export const Input = styled.input`
  max-width: 450px;
  height: 44px;
  background: #fff;
  border: 1px solid #D1D1D1;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  padding: 0 15px;
  border-radius: 6px;
`
