import styled, {css} from 'styled-components/macro'
import { motion } from 'framer-motion'

export const Expand = styled(motion.div)`
  position: absolute;
  top: 55px;
  left: 50%;
  transform: translatex(-50%);
  background: ${({ clr }) => clr.primary};
  max-width: 100vw;
  overflow: hidden;
  border-radius:9px;
  font-size:0.75rem;
  box-shadow: 0px 3px 8px #000000;
`
export const Name = styled.div`
  white-space: nowrap;
  font-weight: 600;
`
export const Option = styled.div`
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  justify-content: space-between;
  cursor: pointer;
  padding-right: 10px;
  position: relative;
  :hover {
    opacity: 0.5;
  }
  /* :before {
    content: '';
    background: #fff;
    width: 5px;
    height: 5px;
    position: absolute;
    border-radius: 100%;
    left: 10px;
    top: 50%;
    transform: translate(0, -50%);
  } */
`
export const Cell = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 20px;
  overflow: hidden;
  flex: 1;
`
export const Sub = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  :hover {
    transform: scale(1.2);
  }
`
export const Split = styled.div`
  height: 2px;
  background-color: #ffffff30;
  width: 90%;
  margin: 0 auto;
`
export const Text = styled.div( () => css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  color: ${ clr.primary_text } ;
`)