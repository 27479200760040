import { Image }                from './Compare.Portal.Swiper.Image'
import { Info }                 from './Compare.Portal.Swiper.Info'
import { Price }                from './Compare.Portal.Swiper.Price'
import { LikeShare }            from './Compare.Portal.Swiper.LikeShare'

import { comparePropertyNames } from './Compare.Portal.Swiper.utilities'
import { groupNamesArr }        from './Compare.Portal.Swiper.utilities'
import { createGroupArr }       from './Compare.Portal.Swiper.utilities'

import { useScrollContainer } from 'react-indiana-drag-scroll'

import { Pad, Row, GroupHead, Wrap } from './Compare.Portal.Swiper.style'

export const CompareSwiper = ({comps,showDiff}) => {

  const a = comparePropertyNames(comps)
  const b = groupNamesArr(comps)

  const scrollContainer = useScrollContainer()

  return (
    <Wrap ref={scrollContainer.ref}>
      <span>
        <Row>{ comps?.map(({Gallery},j) => <Pad count={comps.length} key={j}><Image     data={Gallery} j={j}/></Pad> ) }</Row>
        <Row>{ comps?.map(({Data},j)    => <Pad count={comps.length} key={j}><Info      data={Data}/>         </Pad> ) }</Row>
        <Row>{ comps?.map(({Data},j)    => <Pad count={comps.length} key={j}><Price     data={Data}/>         </Pad> ) }</Row>
        <Row>{ comps?.map(({Data},j)    => <Pad count={comps.length} key={j}><LikeShare data={Data}/>         </Pad> ) }</Row>
      </span>
      {
        b.map( groupName => {
          const isEmptyGroup = a?.filter(x => x.Group == groupName && x.Different.IsDifferent ).length == 0 
          const hide = showDiff && isEmptyGroup
          if ( !hide ) return (
            <span key={groupName}>
              <GroupHead>{groupName}</GroupHead>
              <Row>{ comps?.map(({Data},j) => {
                const c = createGroupArr(Data,a)
                return (
                  <Pad key={j} count={comps.length}> 
                  {
                    c[groupName]?.map( (item,index) => {
                      const value = item.Value === true ? 'Ja' : ( item.Value === false ? 'Nej' : item.Value )
                      const label = item.Label
                      const postfix = item.Postfix ?? ''
                      if ( showDiff && !item.IsDifferent ) { return null }
                      if ( item.Value == '<<HousingBenfit>>' ) { return null }
                      return (
                        <div key={index} style={{display:'flex',justifyContent:'space-between',fontSize:'1.0625rem',padding:'4px 10px'}}>
                          <span>{ label }</span>
                          <span style={{color:item.IsDifferent ? '#b07237' : '#000'}}>{ `${ value || '-' }${ postfix}` }</span>
                        </div>
                      )
                    } )
                  }
                  </Pad>
                )
              } ) }</Row>
            </span>
          )
        })
      }
    </Wrap>
  )
} 

