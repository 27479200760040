import { useState } from "react";
import { useEffect } from "react";
import { getFocus, useGet } from "state/jotai"
import { active_project } from "state/store.global"
import { _project } from "state/store.projects"

export const options = () => { 
  const { pin, pid } = useGet(active_project);
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? [];

  return [
    { presentation: `${buttonsLabel?.filter( btn => btn?.UILabelProperty === 'overview')[0]?.Label}`, path: '/isometry'      , "URLSafe":`${buttonsLabel?.filter( btn => btn?.UILabelProperty === 'overview')[0]?.Label}` },
    { presentation: `${buttonsLabel?.filter( btn => btn?.UILabelProperty === 'North')[0]?.Label}`   , path: '/isometry/North', "URLSafe":`${buttonsLabel?.filter( btn => btn?.UILabelProperty === 'North')[0]?.Label}` },
    { presentation: `${buttonsLabel?.filter( btn => btn?.UILabelProperty === 'South')[0]?.Label}`  , path: '/isometry/South', "URLSafe":`${buttonsLabel?.filter( btn => btn?.UILabelProperty === 'South')[0]?.Label}` }
  ]
}

export const dropdownOptions = {
  en: {
    Overview: "Overview",
    North: "North",
    South: "South"
  },
  sv: {
    Overview: "Översikt",
    North: "Nörd",
    South: "Söder"
  }
}