import { drawer_animation_done } from 'state/store.global'
import { _drawer }               from 'state/store.global'
import { vis_desktop_nav }       from 'state/store.global'
import { useAtom }               from 'state/jotai'
import { AnimatePresence }       from 'framer-motion'
import { motion }                from 'framer-motion'
import { useBreak }              from 'hooks/useBreak'
import { Aside }                 from './Drawer.style'
import { Filter }                from 'components/Filter/Filter'
// import { Detail }                from 'components/Detail/Detail'
import { UnitDrawer }            from 'components/Unit'
import { useMediaQuery } from '@mui/material'
import { useLocation } from 'react-router-dom'

export const Drawer = ({ filter, sorter, detail, w,mw, style }) => {

  const drawer   = useAtom(_drawer)[0]  
  const isFilter = drawer.filter && filter
  const isDetail = drawer.detail && detail
  const here = useLocation().pathname;
  const isIsometry = here.includes("isometry");

  return (
    <AnimatePresence>
      { isFilter && <AnimatedDrawer mw={mw} left filter={filter} style={style} isIsometry={isIsometry}><Filter/></AnimatedDrawer> }
      { isDetail && <AnimatedDrawer w={w} right><UnitDrawer /></AnimatedDrawer> }
    </AnimatePresence>
  )
}

const AnimatedDrawer = ({left, right, children, w, filter, style,isIsometry}) => {
  const here = useLocation().pathname;
  const matches = useMediaQuery('(max-width:1600px)');
  const setState = useAtom(drawer_animation_done)[1]
  const nav      = useAtom(vis_desktop_nav)[0]
  const isMobile = useBreak('md_dn')
  
  const y_out = isMobile ? '100%' : 0 
  const y_in  = isMobile ? 0 : 0
  
  const x_out = isMobile ? 0 : left ? - (nav?300:0) - w + 'px' : w + 'px' 
  const x_in  = isMobile ? 0 : 0

  const aside = {
    initial   : { x:x_out , y:y_out },
    animate   : { x:x_in  , y:y_in  },
    exit      : { x:x_out , y:y_out },
    transition: { duration:0.3, ease:'linear' }
  }
  const animation = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.5, ease: "linear" },
  };

  return ( 
    <Aside 
      {...(filter ? animation : aside)}
      layout
      clr                 = {clr}
      as                  = {motion.aside}
      left                = {left?.toString()}
      right               = {right?.toString()}
      w                   = {w}
      nav                 = {nav}
      onAnimationStart    = {()=>setState(false)}
      onAnimationComplete = {()=>setState(true)}
      filter={filter}
      style={style}
      matches={matches}
      isIsometry={isIsometry}
      >
      { children }
    </Aside> 
  )
}