import styled        from 'styled-components/macro'
import { sb  }       from 'styles/styles.scrollbar'
import { useRef }    from 'react'
import { useScroll } from 'react-use'
import { scrolly }   from 'state/store.global'
import { useSet }    from 'state/jotai'
import { BREAK }     from 'constants'


export const Scroll = ({ children }) => {

  const setScroll = useSet(scrolly)
  const handleScroll = (e) => setScroll(e.currentTarget.scrollTop)

  return (
    <>
      <StyledScroll onScroll={handleScroll}>
       { children }
      </StyledScroll> 
    </>
  )

}

const StyledScroll = styled.div`
  /* ${sb.show}; */
  /* padding: 0px 45px 0 50px; */
  /* ${BREAK.md_dn('padding:0 15px 0 20px')} */
  /* margin:0 20px; */
  /* overflow-x: hidden; */
  /* overflow-y: scroll; */
  /* width: 100%; */
  /* height: 100%; */
  border:10px solid red;
`