import { Grid, Box, Button, Stack } from "@mui/material";
import { Icon } from "components/Icons";
import { useOpenHouseData } from "components/OpenHouseBanner/OpenHouseBanner.Data";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next'
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";
import { useActiveUnit } from "./hooks";

function OpenHouse() {
  const textRef = useRef(null);
  const unit = useActiveUnit();
  const unitId = parseInt(unit.getId());
  const [isAddrExpanded, setIsAddrExpanded] = useState(false)

  const { ready, MM, MMM, Title, Time, Description, Address } = useOpenHouseData();
  const { i18n:{language} } = useTranslation()

  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  useEffect(()=> {
    if (textRef.current) {
      const width = textRef.current.getBoundingClientRect().width;
      if((width + 16) > 270) {
        setIsAddrExpanded(true)
      }
    }
  }, [unitId])
  
  return (
    <>
      {ready && (
        <Box sx={{ px: { xs: isAddrExpanded ? "24px" : 2.625, lg: isAddrExpanded ? "24px" :  6.25}, mt: 3}}>
          <Stack direction="row" sx={{ py: { xs: 1, md: 1}, minHeight: "104px", border: "1px solid", borderColor: "common.black", lineHeight: 1.3, borderRadius: "6px", width: isAddrExpanded ? "402px" : "auto", mx: 'auto'  }} >
            {/* left */}
            <Box sx={{ px: {xs: 1},borderRight: "1px solid", minWidth: "77px", borderRightColor: "common.black", textAlign: "center", lineHeight: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: 'center', flexGrow: { md: 1} }} >
              <Box sx={{ fontSize: 35, fontWeight: "bold", mb: "2px", }} >
                {MM}
              </Box>
              <Box sx={{ fontSize: 16, }} >
                {MMM}
              </Box>
            </Box>
            {/* right */}
            <Box sx={{ px: {xs: 1}, flexGrow: { md: 5}, display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <strong className="paragraph1-bold">{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'OpenHouseTitle')[0]?.Label} {Title}</strong>
              <div className="paragraph2-light">{Time}</div>
              <div ref={textRef} className="paragraph2-light" style={{ width: 'fit-content'}} dangerouslySetInnerHTML={{ __html:Address}}>
              </div>
              {/* commented unit we get a proper openhouse link */}
              {/* <Button  className="paragraph1-light" href={``} sx = {{ px: 0, py: 0.5, justifyContent: "flex-start"}} endIcon = {<Icon icon="long-arrow" />} >
                { buttonsLabel?.filter( btn => btn.UILabelProperty === 'OpenHouseBtnLabel')[0]?.Label }
              </Button> */}
            </Box>
          </Stack>
        </Box>
      )}
    </>
  );
}

export default OpenHouse;
