import { useEffect }      from 'react'
import { useFetch }       from 'hooks/fetch/useFetch'
import { ip }             from 'state/store.global'
import { useGet, useSet } from 'state/jotai'
import { IP }             from 'api/api'

export const GetIP = () => {
  
  const { D } = useFetch({ url:IP })
  // const setIP = useSet(ip)
  
  useEffect(()=>{
    if ( D ) {
      // setIP( D?.IP )
      console.log( 'GetIP', D?.IP )
    }
  },[D])

  return null
}
