import { css } from 'styled-components'
import { FONT, BREAK } from 'constants'

export const FontBreakpoints = css`
  ${'' /* font-size: 0.75rem; */}
  ${'' /* @media (min-width:768px)  { font-size:0.85rem; } */}
  ${'' /* @media (min-width:1400px) { font-size:1.00rem; } */}
`

export const font = css`
  body {
    font-family: ${FONT.FAMILY};
    color: ${FONT.COLOR};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    ${FontBreakpoints}
  }
`