import { CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";
import { appCreateTheme } from "./create-theme";
import PropTypes from "prop-types";
import './custom.css';

function AppThemeProvider(props) {
  const theme = appCreateTheme();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  );
}

AppThemeProvider.propTypes = {
  children: PropTypes.any,
};

export default AppThemeProvider;
