import { Box } from "@mui/material";
import styled from 'styled-components/macro'; // Import styled-components
import { useState } from "react"; // Import useState hook for state management
import { useBreak } from "hooks/useBreak";
import { useLocation, useNavigate } from "react-router-dom";
import { getFocus, useAtom } from "state/jotai";
import { _bootstrap } from "state/store.bootstrap";
import { Icon } from "assets/Icons_";
import { SelectFunction } from "components/SelectFunction/SelectFunction";
import { ShiftProperty } from "components/ShiftProperty/ShiftProperty";
import { Controls } from "components/Isometry/Isometry.Controls";
import { getIsoData, setIsoData } from "state/jotai.isometryDataHooks";
import { Status } from "components/StatusPopup/StatusPopup";
import { useTranslation } from "react-i18next";
import { _drawer, currentActiveProject } from "state/store.global";
import { PoweredBy } from "components/PoweredBy/PoweredBy";

// Styled components inline definition
const FullWidth = styled.div`
    position: fixed;
    bottom: 0px;
    width: 100%;
    z-index: 50;
    background-color: ${({ clr, isBgTrue }) => isBgTrue && `${clr?.footer_bgcolor}`};
    /* Add additional styling properties as needed */
`;

const FlexBox = styled.div`
    padding: ${({ isIsometry }) => (isIsometry ? "16px 48px 8px 48px" : "16px 48px 16px 48px")};
    display: flex;
    
    justify-content: space-between;
    align-items: center;
    color: ${({ clr }) => clr?.primary_text};
    font-size: 1.125rem;
    transform: translate(0, 0);
   

    @media (max-width: 768px) {
        padding: 8px 16px 8px 16px;
        font-size: 1rem;
        width: 100%;
        border-radius: 0;
    }
`;

const Section = styled.div`
    display: flex;
    background-color: ${({ clr, pin, type, isIsometry, isMobile }) =>
        type === "poly" && pin >= 0 && isIsometry
            ? isMobile
                ? `${clr?.primary}bf`
                : `#ffffffbf`
            : `${clr?.primary}bf`};
    height: 40px;
    border-radius: 6px;
    border: 2px solid
        ${({ clr, pin, type, isIsometry, isMobile }) =>
        type === "poly" && pin >= 0 && isIsometry
            ? isMobile
                ? `${clr?.primary}`
                : `#ffffff`
            : `${clr?.primary}`};
    justify-content: center;
    align-items: center;
    padding: ${({ padding }) => (padding ? padding : "0")};
    gap: 8px;
    line-height: 1;
    cursor: pointer;
    user-select: none;

    @media (min-width: 769px) {
        height: 40px;
        width: ${({ width }) => (width ? width : "185px")};
    }
`;

// Component definition
export const NewIsometryDualMenu = ({
    left,
    right,
    nav,
    pin,
    openSelectFunction,
    showSelectFunction,
    setShowSelectFunction,
    clickList,
    list,
    buttonsLabel,
    showProperty,
    setShowProperty,
    toggleDrawer,
    config,
    rotatemode,
    click2,
    turnBuilding,
    isrotate,
    click1,
    clickModel,
    filterShouldShow,
    clickFilter,
    filter,
    state,
    setState,
    closeDrawer,
    setShowFilter,
    setShowMenu,
    atModel,
   
}) => {
    const type = getFocus(_bootstrap, "type");
    const navigate = useNavigate();
    const names = getFocus(_bootstrap, "projectnames");
    const { i18n: { language } } = useTranslation();

    const isMobile = useBreak("md_dn");
    const here = useLocation().pathname;
    const isIsometry = here.includes("isometry");

    const frame = getIsoData("activeframe");
    const frames = getIsoData("frames");
    const index = getIsoData("snapindex");
    const setIndex = setIsoData("snapindex");
    const setFrame = setIsoData("activeframe");
    const percent = getIsoData('percent');
    const setActiveProject = useAtom(currentActiveProject)[1];
    const drawer   = useAtom(_drawer)[0] 
  const isDetail = drawer.detail


    const showButton = [
        "list",
        "compare",
        "favorites",
        "gallery",
        "downloads",
    ].some((i) => here.includes(i));

    const props = { type, isIsometry, pin };

    // State to manage "Drej bygning" section visibility
    const [showDrejBygning, setShowDrejBygning] = useState(false);

    return (

        <FullWidth
            isMobile={isMobile}
            left={left}
            right={right}
            nav={nav}
            clr={clr} // Assuming clr is defined somewhere or passed as prop
            isBgTrue={isMobile ? true : false}
        >
            <FlexBox clr={clr} isIsometry={isIsometry}>
                {isMobile ? (
                    <Section clr={clr} onClick={openSelectFunction} padding="20px">
                        <Icon
                            icon="ellipsisvertical"
                            color={clr.primary_text}
                            isFaIcon={true}
                            size="xl"
                        />
                        <SelectFunction
                            showSelectFunction={showSelectFunction}
                            setShowSelectFunction={setShowSelectFunction}
                        />
                    </Section>
                ) : (

                    <>

                        <Box
                            sx={{
                                width: isMobile && !isIsometry ? "100%" : "auto",
                                display: "flex",
                                justifyContent: isMobile && !isIsometry ? "space-between" : "flex-start",
                                gap: "16px",
                                position: "relative",
                            }}
                        >
                            {!turnBuilding && (
                                <Section
                                    {...props}

                                    active={here.includes("list")}
                                    onClick={clickList}
                                >
                                    <Icon {...list} isFaIcon={true} color={"#000"} />
                                    <div className="paragraph1-regular" style={{ color: "#000" }}>
                                        {buttonsLabel?.filter((btn) => btn.UILabelProperty === "Boligliste")[0]?.Label}
                                    </div>
                                </Section>
                            )}
                            {filterShouldShow && !turnBuilding && (
                                <Section
                                    {...props}
                                    pin={pin}
                                    clr={clr}
                                    showButton={showButton}
                                    onClick={clickFilter}
                                >
                                    <Icon  {...filter} isFaIcon={true} color="#000" />
                                    <div className="paragraph1-regular" style={{ color: "#000" }}>
                                        {buttonsLabel?.filter((btn) => btn.UILabelProperty === "Anvendfilter")[0]?.Label}
                                    </div>
                                </Section>
                            )}

                            {type === "poly" && pin > 0 && !turnBuilding && (
                                <Section
                                    {...props}
                                    pin={pin}
                                    clr={clr}
                                    showButton={showButton}
                                    active={true}
                                    onClick={() => {
                                        closeDrawer("detail");
                                        setShowFilter(false);
                                        setShowMenu(false);
                                        setActiveProject("");
                                        navigate(type === "mono" ? `/${language}/isometry` : `/${language + names[language][0].path}`);
                                    }}
                                >
                                    <Icon size={"lg"} color={"#000"} icon="modelover" isFaIcon={true} />
                                    <div className="paragraph1-regular" style={{ color: "#000" }}>
                                        Oversigtskort
                                    </div>
                                </Section>
                            )}
                            {!turnBuilding && (
                                <Section
                                    {...props}
                                    onClick={() => {
                                        setShowProperty(!showProperty);
                                        toggleDrawer();
                                    }}
                                    clr={clr}
                                    showButton={showButton}
                                >
                                    <Icon
                                        icon="buildings"
                                        size="lg"
                                        color={"#000"}
                                        isFaIcon={true}
                                    />
                                    <div className="paragraph1-regular" style={{ color: "#000" }}>
                                        Skift ejendom
                                    </div>
                                    <ShiftProperty
                                        {...props}
                                        showProperty={showProperty}
                                        setShowProperty={setShowProperty}
                                    />
                                </Section>
                            )}
                            {type === "poly" && pin !== 0 &&  !turnBuilding && (
                                <Section
                                    {...props}
                                    pin={pin}
                                    clr={clr}
                                    onClick={() => isMobile && setState(!state)}
                                    onMouseEnter={() => !isMobile && setState(true)}
                                    onMouseLeave={() => !isMobile && setState(false)}
                                >
                                    <Status
                                        pin={pin}
                                        state={state}
                                        setState={setState}
                                        isMobile={isMobile}
                                        {...{ percent }}
                                    />
                                </Section>
                            )}

                            {type === "mono" && (
                                <Section
                                    {...props}
                                    pin={pin}
                                    clr={clr}
                                    onClick={() => isMobile && setState(!state)}
                                    onMouseEnter={() => !isMobile && setState(true)}
                                    onMouseLeave={() => !isMobile && setState(false)}
                                >
                                    <Status

                                        pin={pin}
                                        state={state}
                                        setState={setState}
                                        isMobile={isMobile}
                                        {...{ percent }}
                                        atModel={atModel}
                                    />
                                </Section>
                            )}

                            {(config?.IMAGE_SNAPFRAMES?.length > 0) && isIsometry && !isMobile && (
                                <Section
                                    {...props}
                                    clr={clr}
                                    onClick={rotatemode && isrotate ? click1 : click2}
                                >
                                    <>
                                        {!turnBuilding ? (
                                            <>
                                                <Icon
                                                    icon="rightarrowrotate"
                                                    color={"#000"}
                                                    isFaIcon={true}
                                                    size="lg"
                                                />{" "}
                                                <div className="paragraph1-regular" style={{ color: "#000" }}>
                                                    {
                                                        buttonsLabel?.filter(
                                                            (btn) => btn?.UILabelProperty === "Drejbygning"
                                                        )[0]?.Label
                                                    }
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <Icon
                                                    icon={isrotate ? "pause" : "play"}
                                                    color={"#000"}
                                                    isFaIcon={true}
                                                    size="lg"
                                                />{" "}
                                                <p style={{ color: "#000" }}>{isrotate ? "Stop rotation" : "Play rotation"}</p>
                                            </>
                                        )}
                                    </>
                                </Section>
                            )}
                        </Box>
                        {!turnBuilding && isIsometry && (
                            <Box>
                                <Section width="none">
                                    {!rotatemode && percent == 100 && (
                                        <>
                                            {config?.IMAGE_SNAPFRAMES?.length > 1 && (
                                                <Controls
                                                    {...{
                                                        frame,
                                                        setFrame,
                                                        config,
                                                        frames,
                                                        index,
                                                        setIndex,
                                                    }}
                                                />
                                            )}
                                        </>
                                    )}
                                </Section>
                            </Box>
                        )}
                    </>
                )}
            </FlexBox>
            {isIsometry && <PoweredBy clr="#FFF" padding="0px 0 8px 0" />}

        </FullWidth>
    );
};
