import { css } from 'styled-components'

export const SliderStyle = css`.swiper-slide {
  user-select:none;
  display: flex;
  height: auto;
}`

export const SwiperStyle = css`.swiper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content:center; 
  align-items:flex-end;
}`