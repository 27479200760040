import styled from 'styled-components/macro'
import Switch from 'react-switch'
import { getFocus, useGet } from 'state/jotai';
import { active_project } from 'state/store.global';
import { _project } from 'state/store.projects';

export const DiffSwitch = ({ showDiff, setShowDiff }) => {
  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];

  return (
    <>
      <SwitchLabel>
        <Switch
          onChange      = {() => setShowDiff(a => !a)}
          checked       = {showDiff}
          uncheckedIcon = {false}
          checkedIcon   = {false}
          height        = {20}
          width         = {48}
          onColor       = {'#cec'}
          offColor      = {'#eee'}
          // handleDiameter={}
        />
        {
          buttonsLabel?.filter( (btn) => btn.UILabelProperty === "Viskunforskelle" )[0]?.Label
        }
      </SwitchLabel>
    </>
  )

}

const SwitchLabel = styled.label`
  display: flex;
  align-items: center;
  line-height: 2;
  gap: 5px;
  font-size: 1.0625rem;
  font-weight: 400;
`