import { atom } from 'jotai'

export const _project = atom({
  openhouse      : [],
  activefilters  : [],
  activesorting  : [],
  cardtext       : [],
  statustypes    : [],
  unittypes      : [],
  unitcategories : [],
  sorteroptions  : [],
  filteroptions  : [],
  units          : [],
  unitsraw       : [],
  handpicked     : [],
  gallery        : [],
  downloads      : [],
  buttonsLabel: [],
  unitStatuses: [],
  Highlights: [],
  ContactProperties: [],
  IconLookup: [],
  menuItems: []
})