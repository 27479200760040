import styled from 'styled-components/macro'

const d = {
  'up':`
    M5.2 0a.5.5 0 0 0-.4.2
    L0 7.2a.5.5 0 0 0 .4.8
    h10.1a.5.5 0 0 0 .4-.8
    L5.6.2a.5.5 0 0 0-.4-.2
    z`,
  'down':`
    M.5 0a.5.5 0 0 0-.4.8
    l4.7 7a.5.5 0 0 0 .8 0
    l5.4-7a.5.5 0 0 0-.4-.8
    H.5z`
}

export const Arrow = ({dir}) => {
  return ( 
    <Svg viewBox='0 0 11.2 8'>
      <path d={d[dir]}/>
    </Svg>
  )
}

const Svg = styled.svg`
  width:8px;
  height:8px;
  /* position:absolute; */
  /* right:10px; */
  /* top:50%; */
  /* transform:translatey(-50%); */
  display:flex;
  justify-content:center;
  align-items:center;
  cursor:pointer;
`