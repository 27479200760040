import { Box } from "@mui/material";
import { UnitPage } from "components/Unit";
import useUnitPage from "components/Unit/useUnitPage";
import { useBreak } from "hooks/useBreak";
import React, { useEffect } from "react";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import { Scrollbar } from "react-scrollbars-custom";

export const ViewUnit = () => {
  const isDesktop    = useBreak('md_up')

  const containerRef = useRef(null);

  const [, setRef] = useUnitPage();

  useEffect(() => {
    setRef(isDesktop ? containerRef.current : null);
  }, [isDesktop]);

  return (
    <>
    {
      isDesktop ?
      <Scrollbar ref={containerRef} trackYProps={{
        renderer: (props) => {
          const { elementRef, ...restProps } = props;
          return <span {...restProps} ref={elementRef}
          className="trackY" style={{ ...restProps.style }} />;
        },
      }}>
         <RenderComponent containerRef={containerRef} />
      </Scrollbar>
      :
      <RenderComponent containerRef={containerRef} />
    }
    </>
  );
};


const RenderComponent = ({ containerRef }) => {
  const isDesktop    = useBreak('md_up')
  const UnitID = useParams().UnitID;
  
  return  <Box
          ref={containerRef}
          sx={{
            width: "100%",
            height: "100%",
            overflow: isDesktop ? "auto" : "hidden"
          }}
        >
        <UnitPage unitId={parseInt(UnitID)} />
    </Box>
}