import { useMousetrap }    from 'use-mousetrap'
import { useNavigate }     from 'react-router-dom'
import { useAtom }         from 'state/jotai'
import { toggle_drawer }   from 'state/actions'
import { close_drawer }    from 'state/actions'
import { vis_compare }     from 'state/store.global'
import { vis_desktop_nav } from 'state/store.global'

export const Hotkeys = () => {

  const toggleDrawer = useAtom(toggle_drawer)[1]
  const closeDrawers = useAtom(close_drawer)[1]
  const setComp      = useAtom(vis_compare)[1]
  const setNav       = useAtom(vis_desktop_nav)[1]
  const navigateTo   = useNavigate()

  useMousetrap('q q q', () => alert('q q q') )
  useMousetrap('a a a', () => alert('a a a') )
 
  useMousetrap('1',() => toggleDrawer('filter'))
  useMousetrap('2',() => toggleDrawer('sorter'))
  useMousetrap('3',() => toggleDrawer('detail'))
  useMousetrap('0',() => setNav(a => !a))

  useMousetrap('x',() => navigateTo('/en/list'))
  useMousetrap('c',() => navigateTo('/en/isometry'))
  useMousetrap('v',() => navigateTo('/en/compare'))
  useMousetrap('b',() => navigateTo('/en/gallery'))
  useMousetrap('n',() => navigateTo('/en/downloads'))
  useMousetrap('m',() => navigateTo('/en/airphoto'))
  useMousetrap('å',() => navigateTo('/en/frontpage'))
  useMousetrap('u',() => navigateTo('/en/unit'))

  useMousetrap('esc',() => { 
    closeDrawers();
    setComp(false)
  })

  return null

}