import { useEffect } from 'react'
import { useScalePoint } from 'hooks/useScalePoint' 
import { useBreak } from 'hooks/useBreak'

export const createZppSetup = (bounds, ref, dimensions, setScale, mode, pin) => {
  const isMobile = useBreak('md_dn');
  
  // Calculate scale based on the window's width so that the image fits entirely
  const scale = useScalePoint(bounds,dimensions,mode,isMobile) + ( isMobile ? (pin>0 ? 0.22 : -0.12 ) : (pin == 0 ? 0 : pin == 1 ? 0.09 : 0.16));
  // const scale = useScalePoint(bounds, dimensions, mode) + (isMobile ? (pin > 0 ? 0.34 : 0.34) : (pin == 0 ? 0.25 : pin == 1 ? 0.09 : 0.16))
  
  useEffect(() => {
    // Center the image and zoom to fit the full width
    ref.current?.zoomToElement('center', scale, 0, null);
    setScale(scale ? scale : 1);
  }, [scale, bounds, mode]);

  const wrapperProps = {
    wheel: { step: 0.04 },
    limitToBounds: true,
    alignmentAnimation: { sizeX: 0, sizeY: 0 }, // Align image perfectly within bounds
    zoomAnimation: { size: 0 },
    minScale: scale ?? 0,
    maxScale: scale ? 8 : 0,
    onInit: (event) => event.zoomToElement('center', scale, 0, null),
    onZoom: (event) => setScale(event.state.scale),
  };

  const wrapperStyle = {
    width: '100%',
    height: '100%',
    position: 'absolute',
    overflow: 'hidden',
    willChange: 'transform',
  };

  const contentStyle = {
    width: dimensions.W,
    height: dimensions.H,
    overflow: 'hidden',
    willChange: 'transform',
  };

  return {
    wrapperProps,
    wrapperStyle,
    contentStyle,
  };
};
