import { Box, IconButton } from "@mui/material";
import { Icon } from "assets/Icons_";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { toggle_drawer } from "state/actions";
import { getFocus, useAtom, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";

function TopDrawer({ availableDate, backgroundColor, textColor, statusText, statusId, address }) {
  const toggle = useAtom(toggle_drawer)[1];
  const todaydate = new Date().getTime();

  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];

  const handleClose = () => {
    toggle("detail");
  };

  return (
    <Box
      sx={{
        backgroundColor,
        color: textColor,
        // py: { xs: 1, md: 1.5 },
        padding: "16px 16px 8px 24px",
        height: 70,
        borderRadius: "0 0 6px 6px",
        position: "relative",
        gap: "4px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start"
      }}
    >
      <IconButton
        onClick={handleClose}
        color="inherit"
        size="small"
        sx={{
          position: "absolute",
          right: 18,
          top: 9,
          cursor: "pointer",
        }}
      >
        <Icon icon="close" isFaIcon={true} fontSize="inherit" size="xl" />
      </IconButton>
      <div>
        {/* <strong style={{ display: 'inline-block', marginRight: '8px' }}>{statusText}</strong> */}
        <div className="h4" style={{ display: 'inline-block', margin: '0 8px 0 0', color: '#fff' }}>{statusText}</div>
 
        {
        Boolean(availableDate) && statusId === 10 ?
         (
          moment(availableDate, 'DD.MM.YYYY').isBefore(moment())  ? (
            <div className="paragraph2-light" style={{ color: clr?.sub_text_color, display: 'inline', opacity: 0.7 }}>
              {`(${buttonsLabel?.filter(btn => btn.UILabelProperty === "AvailableTagTitle")[0]?.Label})`}
            </div>
          ) : (
            <div className="paragraph2-light" style={{ color: clr?.sub_text_color, display: 'inline', opacity: 0.7
         }}>
              ({buttonsLabel?.filter(btn => btn.UILabelProperty === "AvailableDatePrefix")[0]?.Label} {availableDate})
              {/* (Ledig fra {availableDate}) */}
            </div>
          )
        ) : null}
      </div>
      <Box className="paragraph1-regular" sx={{ color: clr?.sub_text_color, whiteSpace: "nowrap",  height: "21px", overflow: "hidden", textOverflow: "ellipsis" }}>{address}</Box>
    </Box>
  );
}

TopDrawer.propTypes = {
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  statusText: PropTypes.string,
  address: PropTypes.string,
};

TopDrawer.defaultProps = {
  backgroundColor: "#AAAAAA",
  textColor: "#000000",
  statusText: "Ledig",
  address: "",
};

export default TopDrawer;
