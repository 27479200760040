import styled from 'styled-components/macro'
import { useBreak } from 'hooks/useBreak'

export const Center = ({ point }) => {

  const isMobile = useBreak('md_dn')

  return (
    <>
      <StyledCenter 
        point={isMobile ? point?.MOBILE : point?.DESKTOP }
        id='center'
      />
    </>
  )

}

const StyledCenter = styled.div`
  position: absolute;
  left: ${ ({point}) => point?.[0] ?? '' }px;
  top: ${ ({point}) => point?.[1] ?? '' }px;
  background: transparent;
  width: 20px;
  height: 20px;
  border-radius:150%;
`