import styled from 'styled-components/macro'
import { MENU, BREAK_ } from 'constants'

export const FullWidth = styled.div`
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index:50;
  background-color: ${({clr, isBgTrue }) => isBgTrue && `${clr?.footer_bgcolor}` };
  ${BREAK_.md_up} {
    /* margin-left: ${({ nav, left }) =>
      (nav && MENU.DESKTOP_NAV_DRAWER_W) +
      (left && MENU.DESKTOP_FILTER_DRAWER_W)}px; 
    width: ${({ nav, left, right }) =>
      `calc(100% - ${
        300 +
        (nav && MENU.DESKTOP_NAV_DRAWER_W) +
        (left && MENU.DESKTOP_FILTER_DRAWER_W) +
        (right && MENU.DESKTOP_UNIT_DRAWER_W)
      }px)`}; */
  };
  ${BREAK_.md_dn} {
    left: 0;
    width: 100%;
    border-radius: 6px 6px 0px 0px;
  }
  transition: padding-left 0.3s ease, padding-right 0.3s ease,
    margin-top 0.3s ease, margin-left 0.3s ease, height 0.3s ease,
    width 0.3s ease;
`

export const FlexBox = styled.div`
  padding: 16px 48px 16px 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ clr }) => clr?.primary_text};
  font-size: 1.125rem;
  transform: translate(0, 0);
  ${BREAK_.md_dn} {
    padding: 8px 16px;
    font-size: 1rem;
    width: 100%;
    border-radius: 0;
  }
`

export const Splitter = styled.div`
  height: calc(100% - 15px);
  width: 2px;
  background-color: lightgray;
  border-radius: 99px;
`

export const Section = styled.div`
  display: flex;
  background-color: ${({ clr , pin, type, isIsometry, isMobile}) => type === "poly" && pin === 0 && isIsometry ? isMobile ? `${clr?.primary}bf` : `#ffffffbf` : `${clr?.primary}bf`};
  height: 40px;
  border-radius: 6px;
  border: 2px solid ${({ clr , pin, type, isIsometry, isMobile }) => type === "poly" && pin === 0 && isIsometry ? isMobile ? `${clr?.primary}` : `#ffffff` : `${clr?.primary}`};
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => padding ? padding : "0"};
  gap: 8px;
  line-height: 1;
  cursor: pointer;
  user-select: none;
  ${BREAK_.md_up} {
    height: 40px;
    width: ${({width}) => width ? width : '185px'};
  }
`
// export const FooterButton = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   cursor: pointer;
//   gap: 8px;
//   height: 40px;
//   border-radius: 6px;
//   padding: ${({ padding }) => (padding ? padding : "0")};
//   line-height: 1;
//   user-select: none;

//   /* Corrected border logic */
//   border: ${({ clr, variant }) => {
//     if (variant === "primary") return "none";
//     if (variant === "secondary") return "1px solid #D1D1D1";
//     if (variant === "tertiary") return "1px solid #FFF";
//     return "1px solid #BBBBBB"; // Default border
//   }};

//   transition: background-color 0.1s ease-in, border 0.1s ease-in, box-shadow 0.1s ease-in;

//   ${BREAK_.md_up} {
//     height: 40px;
//     width: ${({ width }) => (width ? width : '185px')};
//   }

//   /* Default color for icon and text */
//   color: ${({ variant }) => (variant === 'tertiary' ? '#FFF' : '#000')};

//   /* Background color logic */
//   background-color: ${({ variant, clr, pin, type, isIsometry, isMobile }) => {
//     if (type === "poly" && pin === 0 && isIsometry) {
//       return isMobile && variant === "primary" ? `${clr?.primary}` : variant === "secondary" ? `#ffffffbf` : variant === "tertiary" ? " " : "";
//     }
//     if (type === "mono" && pin === 0 && isIsometry) {
//       return isMobile && variant === "secondary" ? clr?.buttons_text : clr?.primary;
//     }
//     if (type === "poly" && pin !== 0 && isIsometry) {
//       return isMobile && variant === "secondary" ? clr?.buttons_text : clr?.primary;
//     }
//     return variant === "primary" ? clr?.primary : clr?.buttons_text;
//   }};

//   &:hover {
//     background-color: ${({ variant, clr, pin, type, isIsometry, isMobile }) => {
//       if (type === "poly" && pin === 0 && isIsometry) {
//         return isMobile && variant === "primary" ? `${clr?.primary}bf` : variant === "secondary" ? `#ffffffbf` : variant === "tertiary" ? "#fff" : "";
//       }
//       if (type === "mono" && pin === 0 && isIsometry) {
//         return isMobile && variant === "secondary" ? `#ffffffbf` : `${clr?.primary}bf`;
//       }
//       return variant === "primary" ? `${clr?.primary}bf` : "#ffffffbf";
//     }};

//     /* Corrected hover border logic */
//     border: ${({ clr, variant }) => {
//       if (variant === "primary") return "none";
//       if (variant === "secondary") return "1px solid #D1D1D1";
//       if (variant === "tertiary") return "none"; // Custom hover border for tertiary
//       return "1px solid #BBBBBB"; // Default hover border
//     }};

//     /* Change icon and text color on hover */
//     & > .icon-content, & > .text-content {
//       color: #000; /* Change to black on hover */
//     }
//   }

//   &:focus {
//     background-color: ${({ variant, clr, pin, type, isIsometry, isMobile , isStatus }) => {
//       if (isStatus) {
//         return;
//       }
//       if (type === "poly" && pin === 0 && isIsometry) {
//         return isMobile && variant === "primary" ? `${clr?.primary}bf` : variant === "secondary" ? `#ffffffbf` : variant === "tertiary" ? "#fff" : "";
//       }
//       if (type === "mono" && pin === 0 && isIsometry) {
//         return isMobile && variant === "secondary" ? `#ffffffbf` : `${clr?.primary}bf`;
//       }
//       return variant === "primary" ? `${clr?.primary}bf` : "#ffffffbf";
//     }};

//     /* Corrected focus box-shadow logic */
//     box-shadow: ${({ variant, clr, isStatus }) => {
//       if (variant === "primary" && !isStatus) {
//         return `0 0 0 2.5px ${clr?.primary}`; // Primary focus style
//       }
//       if (variant === "secondary" && !isStatus) {
//         return `0 0 0 1.5px #D1D1D1`; // Secondary focus style
//       }
//       if (variant === "tertiary" && !isStatus) {
//         return `0 0 0 2.5px #D1D1D1`; // Tertiary focus style
//       }
//       return "none";
//     }};

//     /* Change icon and text color on focus */
//     & > .icon-content, & > .text-content {
//       color: #000; /* Change to black on focus */
//     }
//   }

//   /* Default icon and text styles */
//   .icon-content {
//     color: #FFF; /* Default white color for icon */
//   }

//   .text-content {
//     color: #FFF; /* Default white color for text */
//   }
// `;









export const FooterButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  height: 40px;
  border-radius: 6px;
  // padding: 12px 0;
    padding: ${({ padding }) => padding ? padding : "0"};
  line-height: 1;
  user-select: none;
  border: ${({ clr, variant }) =>
    variant === "primary" ? "none" : "1px solid #D1D1D1"};
  
  transition: background-color 0.1s ease-in, border 0.1s ease-in, box-shadow 0.1s ease-in;

  ${BREAK_.md_up} {
    height: 40px;
    width: ${({ width }) => (width ? width : '185px')};
  }

  /* Background color logic */
  background-color: ${({ variant, clr, pin, type, isIsometry, isMobile }) => {
    if (type === "poly" && pin === 0 && isIsometry) {
      return isMobile && variant === "primary"
        ? clr?.primary
        : clr?.buttons_text;
    }
    if (type === "mono" && pin === 0 && isIsometry) {
      return isMobile && variant === "secondary"
        ? clr?.buttons_text
        : clr?.primary;
    }
    if(type === "poly" && pin !== 0 && isIsometry ){
      return isMobile && variant === "secondary"
      ? clr?.buttons_text
      : clr?.primary;
    }
    return variant === "primary" ? clr?.primary : clr?.buttons_text;
  }};

  &:hover {
    background-color: ${({ variant, clr, pin, type, isIsometry, isMobile }) => {
      if (type === "poly" && pin === 0 && isIsometry) {
        return isMobile && variant === "primary"
          ? `${clr?.primary}bf`
          : `#ffffffbf`;
      }
      if (type === "mono" && pin === 0 && isIsometry) {
        return isMobile && variant === "secondary"
          ? `#ffffffbf`
          : `${clr?.primary}bf`;
      }
      return variant === "primary" ? `${clr?.primary}bf` : "#ffffffbf";
    }};
    
    border: ${({ clr, variant }) =>
      variant === "primary" ? "none" : "1px solid #D1D1D1"};
  }

  &:focus {
    /* Focus styles for both primary and secondary */
   background-color: ${({ variant, clr, pin, type, isIsometry, isMobile , isStatus}) => {
    if(isStatus){
      return 
    }
      if (type === "poly" && pin === 0 && isIsometry ) {
        return isMobile && variant === "primary"
          ? `${clr?.primary}bf`
          : `#ffffffbf`;
      }
      if (type === "mono" && pin === 0 && isIsometry) {
        return isMobile && variant === "secondary"
          ? `#ffffffbf`
          : `${clr?.primary}bf`;
      }
      return variant === "primary" ? `${clr?.primary}bf` : "#ffffffbf";
    }};
    
    /* box-shadow for primary and secondary variants */
    box-shadow: ${({ variant, clr, isStatus }) => {
      if (variant === "primary" && !isStatus) {
        return `0 0 0 2.5px ${clr?.primary}`;  // Primary focus style
      }
      if (variant === "secondary" && !isStatus) {
        return `0 0 0 1.5px #D1D1D1`;  // Secondary focus style (lighter shadow)
      }
      return "none";
    }};
  }
`;